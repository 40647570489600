





























































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { ImportTemplateSummaryValidations } from '@/constants/validations/import-template-summary.validation';
import { validationMixin } from 'vuelidate';
import { IImportTemplateUpdateModal } from '@/interfaces/importTemplates/import-templat-update-modal.interface';
import { getModule } from 'vuex-module-decorators';
import ImportTemplateModule from '@/store/modules/import-templates.module';
import { IImportTemplate } from '@/interfaces/importTemplates/import-template.interface';
import { BaseFormValidations } from '../base-form-validations/BaseFormValidation';

@Component({
  mixins: [validationMixin],
  validations: ImportTemplateSummaryValidations
})
export default class ImportTemplateFormModal extends BaseFormValidations<
  IImportTemplateUpdateModal
> {
  @Prop({ required: false, default: undefined })
  public importTemplateId?: number;

  public form: IImportTemplateUpdateModal = this.getEmptyForm();

  private importTemplateModule: ImportTemplateModule;

  public constructor() {
    super();

    this.importTemplateModule = getModule(ImportTemplateModule);
  }

  protected getEmptyForm(): IImportTemplateUpdateModal {
    return {
      newTemplateName: '',
      existingTemplateName: '',
      newTemplateChosen: false
    };
  }

  public mounted(): void {
    if (!this.importTemplateId) {
      this.form.newTemplateChosen = true;
      this.$v.form.newTemplateChosen?.$touch();
    }
  }

  public created(): void {
    if (this.importTemplateId) {
      this.importTemplateModule
        .getImportTemplate(this.importTemplateId)
        .then((importTemplate: IImportTemplate) => {
          this.form.existingTemplateName = importTemplate.name;
          this.validate();
        });
    } else {
      this.form = this.getEmptyForm();
    }
  }

  public changeRadioState(value: string): void {
    this.form.newTemplateChosen = value !== 'existingTemplateChosen';
  }

  @Emit('submit')
  public onSubmit(): IImportTemplateUpdateModal {
    return this.form;
  }

  @Emit('cancel')
  public cancel(): void {
    return;
  }

  @Emit('close')
  public close(): void {
    return;
  }

  public reset(): void {
    this.form = this.getEmptyForm();
    this.$v.$reset();
  }

  public submit(): void {
    this.validate();
    if (this.isValid) {
      this.onSubmit();
    }
  }
}
