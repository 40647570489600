import httpClient from '@/plugins/httpClient';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { IFileInfoMapping } from '@/interfaces/importMapping/file-info-mapping.interface';
import { IImportRawDataCommand } from '@/interfaces/tapeCrackers/import-raw-data-command.interface';
import { FormBuilderService } from '@/services/form-builder.service';
import { IFileInfoAndMappingQuery } from '@/interfaces/tapeCrackers/file-info-and-mapping-query.interface';

@Module({
  name: 'tapeCrackerModule',
  namespaced: true,
  dynamic: true,
  store
})
export default class TapeCrackerModule extends VuexModule {
  public fileInfoMapping: IFileInfoMapping = {
    fileInfo: {
      columnsInfo: [],
      recordsCount: 0
    },
    mappings: []
  };

  @Mutation
  public setFileInfoAndMappings(fileInfoMapping: IFileInfoMapping): void {
    this.fileInfoMapping = fileInfoMapping;
  }

  @Action({ rawError: true })
  public async getFileInfoAndMapping(queryCommand: IFileInfoAndMappingQuery): Promise<IFileInfoMapping> {
    const formData: FormData = new FormData();
    formData.append('importFile', queryCommand.importFile);

    if (queryCommand.importTemplateId) {
      formData.append('importTemplateId', queryCommand.importTemplateId.toString());
    }

    const fileInfo = await httpClient.post<IFileInfoMapping>('/tapeCrackers/file/info/mapping', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    this.setFileInfoAndMappings(fileInfo);

    return Promise.resolve(fileInfo);
  }

  @Action({ rawError: true })
  public async import(importRawDataCommand: IImportRawDataCommand): Promise<any> {
    const formData: FormData = FormBuilderService.BuildForm(importRawDataCommand);

    const result = await httpClient.post<any>('/tapeCrackers/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return Promise.resolve(result);
  }
}