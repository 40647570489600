import { ConditionOperation } from '@/enums/condition-operation';
import { RuleOperation } from '@/enums/rule-operation';
import { ImportRuleCondition } from '@/models/importRuleCondition';

export const importRuleConditionOperations: ImportRuleCondition[] = [
    {
        text: 'Equals',
        value: ConditionOperation.Equals,
        dataTypes: ['String', 'Boolean', 'DateTime', 'Decimal', 'Single', 'Int32']
    },
    {
        text: 'Less than',
        value: ConditionOperation.LessThan,
        dataTypes: ['DateTime', 'Decimal', 'Single', 'Int32']
    },
    {
        text: 'Less than or equals',
        value: ConditionOperation.LessThanOrEquals,
        dataTypes: ['DateTime', 'Decimal', 'Single', 'Int32']
    },
    {
        text: 'Greater than',
        value: ConditionOperation.GreaterThan,
        dataTypes: ['DateTime', 'Decimal', 'Single', 'Int32']
    },
    {
        text: 'Greater than or equals',
        value: ConditionOperation.GreaterThanOrEquals,
        dataTypes: ['DateTime', 'Decimal', 'Single', 'Int32']
    },
    {
        text: 'Missing',
        value: ConditionOperation.Missing,
        dataTypes: ['String', 'Boolean', 'DateTime', 'Decimal', 'Single', 'Int32']
    },
    {
        text: 'Like',
        value: ConditionOperation.Like,
        dataTypes: ['String']
    },
    {
        text: 'In',
        value: ConditionOperation.In,
        dataTypes: ['String', 'DateTime', 'Decimal', 'Single', 'Int32']
    },
    {
        text: 'Not Missing',
        value: ConditionOperation.NotMissing,
        dataTypes: ['String', 'Boolean', 'DateTime', 'Decimal', 'Single', 'Int32']
    }
];

export const importRuleOperations: ImportRuleCondition[] = [
    { text: 'Set to', value: RuleOperation.SetTo, dataTypes: ['Boolean', 'String', 'DateTime', 'Decimal', 'Single', 'Int32'] },
    { text: 'Add', value: RuleOperation.Add, dataTypes: ['String', 'DateTime', 'Decimal', 'Single', 'Int32'] },
    { text: 'Substract', value: RuleOperation.Subtract, dataTypes: ['DateTime', 'Decimal', 'Single', 'Int32'] },
    { text: 'Multiply', value: RuleOperation.Multiply, dataTypes: ['Decimal', 'Single', 'Int32'] },
    { text: 'Divide', value: RuleOperation.Divide, dataTypes: ['Decimal', 'Single', 'Int32'] },
    { text: 'Coalesce', value: RuleOperation.Coalesce, dataTypes: ['Boolean', 'String', 'DateTime', 'Decimal', 'Single', 'Int32'] }
];