import httpClient from '@/plugins/httpClient';
import { IImportRule } from '@/interfaces/importRules/import-rule.interface';
import { FiltrationState } from '@/models/filtrationState';
import { QueryBuilderService } from '@/services/query-builder.service';
import { PaginatedResult } from '@/models/paginatedResult';

class ImportTemplateRuleService {
    public async getTemplateRules(searchState: FiltrationState): Promise<PaginatedResult<IImportRule>> {
        const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

        return httpClient.get<PaginatedResult<IImportRule>>('/importTemplateRules', { params: searchParams });
    }

    public async getTemplateRule(id: number): Promise<IImportRule> {
        return httpClient.get<IImportRule>(`/importTemplateRules/${id}`);
    }

    public async create(templateRule: IImportRule): Promise<number> {
        return httpClient.post<number>(`/importTemplateRules`, templateRule);
    }

    public async update(id: number, templateRule: IImportRule): Promise<number> {
        return httpClient.put<number>(`/importTemplateRules/${id}`, templateRule);
    }

    public async remove(id: number): Promise<object> {
        return httpClient.delete<object>(`/importTemplateRules/${id}`);
    }
}

export default new ImportTemplateRuleService();
