import { ILocalRule } from '@/interfaces/runPricingModel/localRule.interface';
import { ILocalRuleDetails } from '@/interfaces/runPricingModel/localRuleDetails.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import httpClient from '@/plugins/httpClient';
import { QueryBuilderService } from './query-builder.service';

class LocalRuleService {
    public getLocalRules(searchState: FiltrationState): Promise<PaginatedResult<ILocalRule>> {
        const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

        return httpClient.get<PaginatedResult<ILocalRule>>('/localRules', { params: searchParams });
    }

    public getById(id: number): Promise<ILocalRuleDetails> {
        return httpClient.get<ILocalRuleDetails>(`/localRules/${id}`);
    }

    public create(localRule: ILocalRuleDetails): Promise<any> {
        return httpClient.post<any>(`/localRules`, localRule)
    }

    public async update(id: number, localRule: ILocalRuleDetails): Promise<number> {
        return httpClient.put<number>(`/localRules/${id}`, localRule);
    }

    public getExistLocalRuleOrDefault(name: string, id?: number): Promise<ILocalRule> {
        let url: string = `/localRules/exists?name=${name}`;

        if (id) {
            url += `&id=${id}`;
        }
        return httpClient.get<ILocalRule>(url, { headers: { useGlobalLoader: false, useToastNotification: false } });
    }


    public copy(id: number): Promise<number> {
        return httpClient.post<number>(`/localRules/${id}/copy`)
    }

    public getParameters(id: number): Promise<string> {
        return httpClient.get<string>(`/localRules/${id}/parameters`);
    }
}

export default new LocalRuleService();