






import Vue from 'vue';
import Loader from '@/components/ui/Loader.vue';

export default Vue.extend({
  name: 'App'
});
