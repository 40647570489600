import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';

export const MappingOptionsValidations = {
    form: {
        $each: {
            dbColumn: {
                required
            }
        }
    }
};