export enum JsonQueryRuleOperator {
    Not = 1,
    IsTrue = 2,
    IsNull = 3,
    AllNull = 4,
    GreaterThan = 5,
    LessThan = 6,
    Equals = 7,
    WithinRange = 8,
    ExcludeRange = 9,
    In = 10,
    StringIn = 11,
    Is = 12,
    AlwaysTrue = 13,
    Logica = 14,
    IsFalse = 15,
    IsNotNull = 16
}