import { DirectiveOptions } from 'vue';

const DisabledDirective: DirectiveOptions = {
    update(el, binding): any {
        const inputs = el.getElementsByTagName('input');
        for (const input of inputs) {
            input.disabled = binding.value;
        }
    }
};

export default DisabledDirective;