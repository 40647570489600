import { ConditionOperation } from '@/enums/condition-operation';
import { RuleArgumentType } from '@/enums/rule-argument-type';
import { RuleOperation } from '@/enums/rule-operation';
import { required, requiredIf } from 'vuelidate/lib/validators';

export const ImportRuleValidations: any = {
    form: {
        appliesTo: {
            required
        },
        conditionLeftOperand: {
            required
        },
        conditionOperation: {
            required
        },
        conditionRightOperandType: {
            required: requiredIf((form) => {
                return form.conditionOperation !== null
                    && form.conditionOperation !== ConditionOperation.Missing
                    && form.conditionOperation !== ConditionOperation.NotMissing;
            })
        },
        conditionRightOperand: {
            required: requiredIf((form) => {
                return form.conditionOperation !== null
                    && form.conditionOperation !== ConditionOperation.Missing
                    && form.conditionOperation !== ConditionOperation.NotMissing;
            })
        },
        ruleArgument1Type: {
            required
        },
        ruleArgument1Value: {
            required: requiredIf((form) => {
                return form.ruleArgument1Type !== RuleArgumentType.Nullable;
            })
        },
        ruleOperation: {
            required: requiredIf((form) => {
                return form.ruleOperation;
            })
        },
        ruleArgument2Type: {
        },
        ruleArgument2Value: {
            required: requiredIf((form) => {
                return form.ruleOperation !== RuleOperation.SetTo && form.ruleOperation !== RuleOperation.Execute;
            })
        }
    }
};