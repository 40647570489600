



























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { RunPricingDataType } from '@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface';
import { withPopper } from '@/plugins/popover';
import { operationTypes } from '@/components/run-pricing-model/parameters/constants/operationTypes.const';

@Component({
  data: () => {
    return {
      RunPricingDataType,
      operationTypes
    };
  }
})
export default class ParametersChangesModal extends Vue {
  // properties
  @Prop({ required: true }) text: string;

  public popover = withPopper;

  public constructor() {
    super();
  }

  @Emit('close')
  public onClose(): void {
    return;
  }
}
