
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class AutoComplete extends Vue {
  @Prop({ required: false, default: () => [] })
  public items: string[];

  @Prop({ required: false, default: {} })
  public addLabelClasses: any;

  @Prop({ required: false, default: true })
  public isValid: any;

  @Prop({ required: false, default: '' })
  public placeholder: string;

  @Prop({ required: false, default: '' })
  public value: any;

  @Prop({ required: false, default: 'text' })
  public type: string;

  public isOpen: boolean = false;
  public results: string[] = [];
  public isLoading: boolean = false;
  public arrowCounter: number = 0;

  public booleanTypeOptions: any[] = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' }
  ];

  public get valueInput(): string {
    return this.value;
  }

  public set valueInput(newValue) {
    this.$emit('update:value', newValue);

    if (this.isMenuDisplay) {
      this.filterResults(newValue);
      this.isOpen = true;
    }
  }

  public get isMenuDisplay(): boolean {
    return !this.type.includes('Boolean') && !this.type.includes('Date');
  }

  public mounted(): void {
    document.addEventListener('click', this.handleClickOutside);
  }

  public destroyed(): void {
    document.removeEventListener('click', this.handleClickOutside);
  }

  @Watch('items')
  public onItemsChanged(value: string[], oldValue: string): void {
    if (value.length !== oldValue.length) {
      this.results = value;
      this.isLoading = false;
    }
  }

  @Watch('value')
  valueChanged(newValue: string, oldValue: string): void {
    if (newValue?.valueOf() !== oldValue?.valueOf()) {
      this.value = newValue;
    }
  }

  public filterResults(newValue: string): void {
    this.results = newValue
      ? this.items.filter((item) => {
          return newValue
            ? item.toLowerCase().indexOf(newValue.toLowerCase()) > -1
            : -1;
        })
      : this.items;
  }

  public setResult(result: string): void {
    this.valueInput = result;
    this.isOpen = false;
  }

  public onFocus(): void {
    this.filterResults(this.valueInput);
    if (this.results.length > 0) {
      this.isOpen = true;
    }
  }

  public onArrowDown(evt: any): void {
    if (this.arrowCounter < this.results.length) {
      this.arrowCounter = this.arrowCounter + 1;
    }
  }

  onArrowUp(): void {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }

  onEnter(): void {
    this.value = this.results[this.arrowCounter];
    this.isOpen = false;
    this.arrowCounter = -1;
  }

  handleClickOutside(evt: any): void {
    if (!this.$el.contains(evt.target)) {
      this.isOpen = false;
      this.arrowCounter = -1;
    }
  }
}
