export class QueryBuilderService {
    public static BuildParametersFromSearch<T>(obj: T): URLSearchParams {
        const params: URLSearchParams = new URLSearchParams();
        const map: { key: string, value: any }[] = [];

        if (obj == null) {
            return params;
        }

        this.populateSearchParams(map, '', obj);
        map.forEach(element => {
            params.append(element.key, element.value);
        });

        return params;
    }

    private static populateArray<T>(params: { key: string, value: any }[], prefix: string, val: T[]): void {
        val.forEach((item: any, index: number) => {
            const key = prefix + '[' + index + ']';
            this.populateSearchParams(params, key, item);
        });
    }

    private static populateObject<T>(params: { key: string, value: any }[], prefix: string, val: any): void {
        const objectKeys: any[] = Object.keys(val) as (keyof T)[];

        if (prefix) {
            prefix = `${prefix}.`;
        }

        for (const objKey of objectKeys) {

            const value: any = val[objKey];
            const key: string = prefix + objKey;

            this.populateSearchParams(params, key, value);
        }
    }

    private static populateSearchParams<T>(params: { key: string, value: any }[], key: string, value: any): void {
        if (value instanceof Array) {
            this.populateArray(params, key, value);
        } else if (value instanceof Object) {
            this.populateObject(params, key, value);
        } else {
            if (value != null) {
                params.push({ key, value: value.toString() });
            }
        }
    }
}