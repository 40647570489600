import { IRunPricingModel } from '@/interfaces/runPricingModel/runPricingModel.interface';
import { IScenarioDetails } from '@/interfaces/runPricingModel/scenarioDetails.interface';
import { ILocalRule } from '../interfaces/runPricingModel/localRule.interface';
import { IGlobalRule } from '../interfaces/runPricingModel/globalRule.interface';
import { IParameterAdjustment } from '../interfaces/runPricingModel/parameterAdjustment.interface';
import { ILocalRuleDetails } from '@/interfaces/runPricingModel/localRuleDetails.interface';

export class RunPricingModelState {
    runPricingModel: IRunPricingModel;
    selectedScenario: IScenarioDetails | null;
    selectedLocalRule: ILocalRule | null;
    selectedGlobalRule: IGlobalRule | null;
    isCreateOrUpdateScenario: boolean;
    isCreateModeActive: boolean;
    globalRule: IGlobalRule | null;
    localRule: ILocalRuleDetails | null;
    parameterAdjustment: IParameterAdjustment | null;
}