import httpClient from '@/plugins/httpClient';
import { FiltrationState } from '@/models/filtrationState';
import { QueryBuilderService } from '@/services/query-builder.service';
import { PaginatedResult } from '@/models/paginatedResult';
import { IDataSource } from '@/interfaces/dataSources/data-source.interface';

class DataSourceService {
    public async getDataSources(searchState: FiltrationState): Promise<PaginatedResult<IDataSource>> {
        const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

        return httpClient.get<PaginatedResult<IDataSource>>('/dataSources', { params: searchParams });
    }
}

export default new DataSourceService();
