export class FormBuilderService {
    public static BuildForm(model: any, form: FormData | null = null, namespace: string = ''): FormData {
        const formData: FormData = form || new FormData();

        for (const propertyName of Object.keys(model)) {
            if (!model.hasOwnProperty(propertyName) || !model[propertyName]) {
                continue;
            }

            const formKey: string = namespace ? `${namespace}.${propertyName}` : propertyName;

            if (model[propertyName] instanceof Date) {
                formData.append(formKey, model[propertyName].toISOString());
            } else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach((element: any, index: number) => {
                    const tempFormKey: string = `${formKey}[${index}]`;
                    if (typeof element === 'object') {
                        this.BuildForm(element, formData, tempFormKey);
                    } else {
                        formData.append(tempFormKey, element);
                    }
                });
            } else if (model[propertyName] instanceof File) {
                formData.append(formKey, model[propertyName], model[propertyName].name);
            } else if (typeof model[propertyName] === 'object') {
                this.BuildForm(model[propertyName], formData, formKey);
            } else {
                formData.append(formKey, model[propertyName].toString());
            }
        }

        return formData;
    }
};
