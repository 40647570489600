import { IParameterAdjustment } from '@/interfaces/runPricingModel/parameterAdjustment.interface';
import { IParameterAdjustmentDetails } from '@/interfaces/runPricingModel/parameterAdjustmentDetails.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import httpClient from '@/plugins/httpClient';
import { QueryBuilderService } from './query-builder.service';

class ParameterAdjustmentService {
    public getParameterAdjustments(searchState: FiltrationState): Promise<PaginatedResult<IParameterAdjustment>> {
        const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

        return httpClient.get<PaginatedResult<IParameterAdjustment>>('/parameterAdjustments', { params: searchParams });
    }

    public getById(id: number): Promise<IParameterAdjustmentDetails> {
        return httpClient.get<IParameterAdjustmentDetails>(`/parameterAdjustments/${id}`);
    }

    public create(parameterAdjustment: IParameterAdjustmentDetails): Promise<any> {
        return httpClient.post<any>(`/parameterAdjustments`, parameterAdjustment)
    }

    public async update(id: number, parameterAdjustment: IParameterAdjustmentDetails): Promise<number> {
        return httpClient.put<number>(`/parameterAdjustments/${id}`, parameterAdjustment);
    }

    public copy(id: number): Promise<number> {
        return httpClient.post<number>(`/parameterAdjustments/${id}/copy`)
    }

    public getExistParameterAdjustmentsOrDefault(name: string, id?: number): Promise<IParameterAdjustment> {
        let url: string = `/parameterAdjustments/exists?name=${name}`;

        if (id) {
            url += `&id=${id}`;
        }
        return httpClient.get<IParameterAdjustment>(url, { headers: { useGlobalLoader: false, useToastNotification: false } });
    }
}

export default new ParameterAdjustmentService();