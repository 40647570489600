import { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'
import { axiosInstance } from './configurations/axios.configurations'

class HttpClient {
  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      axiosInstance.get<T>(url, config)
        .then((resp: AxiosResponse<T>) => {
          resolve(resp.data);
        }).catch((rejectedReason: any) => {
          reject(rejectedReason)
        })
    })
  }

  public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      axiosInstance.delete<T>(url, config)
        .then((resp: AxiosResponse<T>) => {
          resolve(resp.data)
        }).catch((rejectedReason: any) => {
          reject(rejectedReason)
        })
    })
  }

  public post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      axiosInstance.post<T>(url, data, config)
        .then((resp: AxiosResponse<T>) => {
          resolve(resp.data)
        }).catch((rejectedReason: any) => {
          reject(rejectedReason)
        })
    })
  }

  public put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      axiosInstance.put<T>(url, data, config)
        .then((resp: AxiosResponse<T>) => {
          resolve(resp.data)
        }).catch((rejectedReason: any) => {
          reject(rejectedReason)
        })
    })
  }
}

export default new HttpClient();
