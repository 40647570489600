import { required, numeric, minValue, maxValue, integer, decimal } from 'vuelidate/lib/validators';
import _ from 'lodash';

const greaterThan = (value: string, start: string): boolean => Number.parseFloat(value) > Number.parseFloat(start);
const lessThan = (value: string, end: string): boolean => Number.parseFloat(value) < Number.parseFloat(end);

export const VectorSegmentParamsValidations = {
    form: {
        $each: {
            vectorLength: {
                required,
                numeric,
                minValue: minValue(1),
                maxValue: maxValue(1000)
            },
            start: {
                required,
                decimal,
                minValue: minValue(-1000000000),
                maxValue: maxValue(1000000000)
            },
            end: {
                required,
                decimal,
                minValue: minValue(-1000000000),
                maxValue: maxValue(1000000000)
            }
        }
    }
};