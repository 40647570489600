import { IDeal } from '@/interfaces/deals/deal.interface';
import { ILoanPool } from '@/interfaces/runPricingModel/loanPool.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import httpClient from '@/plugins/httpClient';
import { QueryBuilderService } from './query-builder.service';

class DealService {
  public getDeals(searchState: FiltrationState): Promise<PaginatedResult<IDeal>> {
    const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

    return httpClient.get<PaginatedResult<IDeal>>('/deals', { params: searchParams });
  }

  public getDealsForLoanPool(searchState: FiltrationState): Promise<PaginatedResult<ILoanPool>> {
    const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

    return httpClient.get<PaginatedResult<ILoanPool>>('/deals/loanPool', { params: searchParams });
  }

  public getExistDealOrDefault(name: string): Promise<IDeal> {
    return httpClient.get<IDeal>(`/deals/exists?name=${name}`, { headers: { useGlobalLoader: false, useToastNotification: false } });
  }

  public getById(id: number): Promise<IDeal> {
    return httpClient.get<IDeal>(`/deals/${id}`);
  }
}

export default new DealService();