





























































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { withPopper } from "@/plugins/popover";
import { IRule } from "@/interfaces/runPricingModel/rule.interface";
import { FiltrationState } from "@/models/filtrationState";
import GlobalRuleService from "@/services/global-rule.service";
import LocalRuleService from "@/services/local-rule.service";
import { PaginatedResult } from "@/models/paginatedResult";
import { ILocalRule } from "@/interfaces/runPricingModel/localRule.interface";
import { IGlobalRule } from "@/interfaces/runPricingModel/globalRule.interface";
import { maxItemsInDropDown } from "@/constants/stateConstants";
import RunPricingModelModule from "@/store/modules/runPricingModel.module";
import { getModule } from "vuex-module-decorators";
import { RunPricingModelState } from "@/models/runPricingModelState";

@Component({})
export default class RulesList extends Vue {
  @Prop({
    required: false,
    default: () => {
      return false;
    },
  })
  isLocal: boolean;

  @Prop({
    required: false,
    default: () => {
      return [];
    },
  })
  existingRules: IRule[];

  public popover = withPopper;

  public get ruleOptions(): IRule[] {
    return [
      ...this.rules.filter(
        (lr) => this.selectedRules.findIndex((flr) => lr.id === flr.id) === -1
      ),
    ];
  }

  private runPricingModelModule: RunPricingModelModule;
  public rules: IRule[] = [];
  public selectedRule: IRule | null = null;
  public deleteRule: boolean = false;
  public selectedRuleId: number;
  public selectedRules: IRule[] = [];
  private numberOfRulesLoaded: number;
  public numberOfItemsLoaded: number = 0;
  public isLoadMore: boolean;
  public search: string | null = null;
  public ruleModalText: string = "";
  public ruleModalShow: boolean = false;

  public constructor() {
    super();
    this.runPricingModelModule = getModule(RunPricingModelModule);
  }

  public created(): void {
    if (this.isLocal) {
      this.selectedRule = this.runPricingModelModule.runPricingModelState
        ?.selectedLocalRule as IRule;
    } else {
      this.selectedRule = this.runPricingModelModule.runPricingModelState
        ?.selectedGlobalRule as IRule;
    }
    this.selectedRules = this.existingRules;
    this.searchRules(null, false, 0, false);
  }

  public searchRules(
    search: string | null,
    loading: boolean,
    skipCount: number,
    isLoadMore: boolean
  ): void {
    const state: FiltrationState = this.createSearchState(search, skipCount);
    if (this.isLocal) {
      this.loadLocalRules(state, isLoadMore);
    } else {
      this.loadGlobalRules(state, isLoadMore);
    }
  }

  private loadLocalRules(state: any, isLoadMore: boolean) {
    LocalRuleService.getLocalRules(state).then(
      (response: PaginatedResult<ILocalRule>) => {
        if (isLoadMore) {
          for (const localRule of response.entities) {
            this.rules.push(localRule);
          }
        } else {
          this.rules = response.entities;
        }

        this.numberOfRulesLoaded += response.entities.length;
        if (response.totalCount <= this.numberOfRulesLoaded) {
          this.numberOfRulesLoaded = 0;
        }
      }
    );
  }

  private loadGlobalRules(state: any, isLoadMore: boolean) {
    GlobalRuleService.getGlobalRules(state).then(
      (response: PaginatedResult<IGlobalRule>) => {
        if (isLoadMore) {
          for (const localRule of response.entities) {
            this.rules.push(localRule);
          }
        } else {
          this.rules = response.entities;
        }

        this.numberOfRulesLoaded += response.entities.length;
        if (response.totalCount <= this.numberOfRulesLoaded) {
          this.numberOfRulesLoaded = 0;
        }
      }
    );
  }

  public createSearchState(
    search: string | null,
    skipCount: number
  ): FiltrationState {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search !== this.search) {
      this.numberOfItemsLoaded = 0;
    }

    this.search = search;

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }
    return state;
  }

  public addRuleToScenario(): void {
    if (this.selectedRule) {
      this.selectedRuleId = <number>this.selectedRule.id;
      this.selectedRules.unshift(this.selectedRule);
      this.selectedRule = null;
    }
  }

  public removeRuleFromScenario(id: number): void {
    this.selectedRules = this.selectedRules.filter((lr) => lr.id !== id);
  }

  public openRuleModal(ruleId: number): void {
    if (this.isLocal) {
      LocalRuleService.getParameters(ruleId).then((value) => {
        this.ruleModalText = value;
      });
    } else {
      GlobalRuleService.getParameters(ruleId).then((value) => {
        this.ruleModalText = value;
      });
    }
    this.ruleModalShow = true;
  }

  public closeRuleModal(): void {
    this.ruleModalShow = false;
  }

  public editRule(ruleId: number): void {
    this.$emit("changingRule");
    this.$router.push({
      name: this.isLocal ? "LocalRule" : "GlobalRule",
      params: { id: ruleId.toString() },
    });
  }

  public createRule(): void {
    this.$emit("changingRule");
    let state = this.runPricingModelModule.runPricingModelState;
    if (state) {
      if(this.isLocal) {
        state.localRule = null;
      } else {
        state.globalRule = null;
      }

      this.runPricingModelModule.updateState(state);
    }
  
    this.$router.push({
      name: this.isLocal ? "LocalRule" : "GlobalRule",
    });
  }

  public copyRule(id: number): void {
    if (this.isLocal) {
      LocalRuleService.copy(id).then((ruleId: number) => this.editRule(ruleId));
    } else {
      GlobalRuleService.copy(id).then((ruleId: number) =>
        this.editRule(ruleId)
      );
    }
  }
  @Watch("selectedRules")
  public returnRulesData(selectedRules: IRule[]): void {
    this.$emit("receiveRulesData", selectedRules);
  }

  @Watch("selectedRule")
  public saveSelectedRule(selectedRule: IRule): void {
    let state = this.runPricingModelModule.runPricingModelState;
    if (!state) {
      state = new RunPricingModelState();
    }
    if (this.isLocal) {
      state.selectedLocalRule = selectedRule as ILocalRule;
    } else {
      state.selectedGlobalRule = selectedRule as IGlobalRule;
    }
  }
}
