import { Vue } from 'vue-property-decorator';
import { ValidationProperties, ValidationEvaluation } from 'vue/types/vue';
import { Validation } from 'vuelidate';

export abstract class BaseFormValidations<TModel> extends Vue {
    protected form: TModel = this.getEmptyForm();

    public get isDirty(): boolean {
        return this.$v.form.$anyDirty;
    }

    public get isValid(): boolean {
        return !this.$v.form.$invalid;
    }

    protected abstract getEmptyForm(): TModel;

    public validate(): void {
        this.$v.$touch();
        this.$v.form.$touch();
    }

    public checkIfValid(fieldName: string, validOnSumit: boolean = true): boolean {
        let field: (Validation & ValidationProperties<any>)
            | (Validation & ValidationProperties<any> & ValidationEvaluation)
            | undefined = this.$v.form;

        for (let i = 0, fieldNames = fieldName.split('.'), length = fieldNames.length; i < length; i++) {
            if (field) {
                field = field[fieldNames[i]];
            }
        };

        if (validOnSumit) {
            return (field && (!field.$invalid || !field.$dirty)) === true;
        } else {
            if (!field || !field.$dirty) {
                return false;
            }
            return !field.$invalid;
        }
    }

    public reset(): void {
        this.form = this.getEmptyForm();
        this.$v.$reset();
    }
}