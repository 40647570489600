import { ApiResponseType } from '@/enums/api-response-type';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@aspnet/signalr';
import { Vue } from 'vue-property-decorator';

const _signalrConfig = {
    BASE_URL: `${process.env.VUE_APP_BASE_URL}/hubs/notification`,
    HUB_MESSAGE_TITLE: 'SignalR',
    RECEIVE_NOTIFICATION: 'ReceiveNotification',
    GET_CONNECTION_ID: 'GetConnectionId'
};

class NotificationHubSettings {
    private static _hub: NotificationHubSettings;
    private _hubConnection: HubConnection;


    public constructor() {
        this.createConnection();
    }

    public static get Instance(): NotificationHubSettings {
        return this._hub || (this._hub = new this());
    }

    public startConnection(): void {
        if (this._hubConnection.state === HubConnectionState.Disconnected) {
            this._hubConnection.start()
                .then(() => {
                    this.registerOnServerEvents();
                })
                .catch(err => {
                    console.log('Error while starting connection: ' + err);
                    setTimeout(() => this.startConnection(), 50000);
                })
        };
    }

    private createConnection(): void {
        const token = localStorage.getItem('adal.idtoken');
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(_signalrConfig.BASE_URL, { accessTokenFactory: () => token ?? '' })
            .build();
    }

    private registerOnServerEvents(): void {
        this._hubConnection.on(_signalrConfig.RECEIVE_NOTIFICATION, (message: string, type: ApiResponseType) => {
            switch (type) {
                case ApiResponseType.Error: Vue.$toast.error(message); break;
                case ApiResponseType.Warning: Vue.$toast.warning(message); break;
                case ApiResponseType.Success: Vue.$toast.success(message); break;
                case ApiResponseType.Info:
                default: Vue.$toast.info(message); break;
            }
        });
    }
}

export const NotificationHub = NotificationHubSettings.Instance;