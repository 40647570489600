import { requiredIf, maxLength } from 'vuelidate/lib/validators';
const mustBeNumericArray = (value: string | null) => !value || new RegExp(/^\d+(,\d+)*$/).test(value);
const notEmpty = (value: string | null) => !value || value.trim().length > 0;

export const StandardizeAddressSummaryValidations = {
    form: {
        activeTab: {
        },
        address: {
            required: requiredIf((form) => {
                return form.activeTab === 0;
            }),
            notEmpty
        },
        city: {
            required: requiredIf((form) => {
                return form.activeTab === 0;
            }),
            notEmpty,
            maxLength: maxLength(50)
        },
        state: {
            required: requiredIf((form) => {
                return form.activeTab === 0;
            }),
            notEmpty
        },
        zipcode: {
            required: requiredIf((form) => {
                return form.activeTab === 0;
            }),
            notEmpty,
            maxLength: maxLength(10)
        },

        loanIds: {
            required: requiredIf((form) => {
                return form.activeTab === 1;
            }),
            mustBeNumericArray
        },
        dealId: {
            required: requiredIf((form) => {
                return form.activeTab === 2;
            })
        }
    }
};