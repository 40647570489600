























import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { JsonQueryRuleModel } from '@/components/json-query-builder/models/JsonQueryRuleModel';
import { JsonQueryConfigModel } from '@/components/json-query-builder/models/JsonQueryConfigModel';
import { JsonQueryRuleSetModel } from '@/components/json-query-builder/models/JsonQueryRuleSetModel';
import _ from 'lodash';
import JsonQueryGroup from './JsonQueryGroup.vue';

@Component({})
export default class JsonQueryBuilder extends Vue {
  @Prop({ required: false }) public options: JsonQueryConfigModel[];
  @Prop({ required: false, default: {} }) public value: JsonQueryRuleSetModel;

  @Ref() public readonly group: JsonQueryGroup;

  @Watch('value', { deep: true })
  public currentRuleFieldChange(): void {
    this.$emit('input', this.value);
  }

  public created(): void {
    this.addUUIDsToCurrentQuery();
  }

  public validate(): boolean {
    const isValid = this.group.validate();
    return isValid;
  }

  private addUUIDsToCurrentQuery(): void {
    const addUUIDsToQueryAndChildren = (
      query: JsonQueryRuleSetModel | JsonQueryRuleModel
    ): void => {
      const keys = Object.keys(query);
      Object.defineProperty(query, '_uuid', {
        enumerable: false,
        value: _.uniqueId()
      });

      if (keys.includes('rules')) {
        (query as JsonQueryRuleSetModel).rules?.forEach(
          (rule: JsonQueryRuleSetModel | JsonQueryRuleModel) => {
            addUUIDsToQueryAndChildren(rule);
          }
        );
      }
    };

    addUUIDsToQueryAndChildren(this.value);
  }
}
