








import { CFooter } from '@coreui/vue';
import Vue from 'vue';
export default Vue.extend({
     components: {
         'c-footer': CFooter
     }
})
