import { DirectiveOptions } from 'vue';
import ConfirmOperationModal from '@/components/ui/ConfirmOperationModal.vue'
import { Vue } from 'vue-property-decorator';

const ConfirmOperationDirective: DirectiveOptions = {
    bind(el, binding, vnode): any {
        el.addEventListener('click', () => openConfirmationModal(el, binding, vnode)
        );
    },
    unbind(el, binding, vnode): any {
        el.removeEventListener('click', () => openConfirmationModal);
    }
};

function openConfirmationModal(el: any, binding: any, vnode: any): void {
    el.blur();
    (el as HTMLInputElement).disabled = true;

    if (vnode.context) {
        const handlers: any = (vnode.data && vnode.data.on) ||
            (vnode.componentOptions && vnode.componentOptions.listeners);

        const ComponentClass = Vue.extend(ConfirmOperationModal);
        const instance = new ComponentClass({
            data(): any {
                return binding.value;
            }
        });
        instance.$mount();
        instance.$on('submit', (e: any) => {
            (el as HTMLInputElement).disabled = false;
            const eventName = 'confirm-operation-submit';

            instance.$destroy();
            instance.$el.remove();

            if (handlers[eventName]) {
                handlers[eventName].fns();
            }
        });

        instance.$on('discard', (e: any) => {
            (el as HTMLInputElement).disabled = false;
            const eventName = 'confirm-operation-discard';

            instance.$destroy();
            instance.$el.remove();

            if (handlers[eventName]) {
                handlers[eventName].fns();
            }
        });

        instance.$on('cancel', (e: any) => {
            (el as HTMLInputElement).disabled = false;
            const eventName = 'confirm-operation-cancel';

            instance.$destroy();
            instance.$el.remove();

            if (handlers[eventName]) {
                handlers[eventName].fns();
            }
        });

        document.querySelector('.c-app')?.appendChild(instance.$el);
    }
}

export default ConfirmOperationDirective;