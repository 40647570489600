









































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

@Component({})
export default class BaseModal extends Vue {
  @Prop({ required: true, default: false }) show: boolean;
  @Prop({ required: false, default: true }) closeOnBackdrop: boolean;
  @Prop({ required: false, default: false }) disableSubmitButton: boolean;
  @Prop({ required: false, default: '' }) content: string;
  @Prop({ required: false, default: 'Yes' }) acceptButtonText: string;
  @Prop({ required: false, default: 'No' }) discardButtonText: string;
  @Prop({ required: false, default: true }) showDiscardButton: boolean;
  @Prop({ required: false, default: 'Confirm operation' }) title: string;
  @Prop({ required: false, default: 'lg' }) size: 'sm' | 'lg' | 'xl';
  @Prop({ required: false, default: '' }) addContentClasses: string;

  @Emit('cancel') public emitCancel(): void {
    return;
  }
  @Emit('close') public emitClose(): void {
    return;
  }
  @Emit('accept') public emitAccept(): void {
    return;
  }
}
