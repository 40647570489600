import { maxLength, required } from 'vuelidate/lib/validators';
import ParameterAdjustmentService from '@/services/parameter-adjustment.service';

const isUnique = async (value: string | null, currentId?: number) => {
    if (!value || value === '') {
        return true;
    } else {
        const resp = await ParameterAdjustmentService.getExistParameterAdjustmentsOrDefault(value, currentId);
        return !resp.id || resp.id < 1
    }
}

export const ParameterAdjustmentValidations = {
    form: {
        id: {},
        name: {
            required,
            maxLength: maxLength(55),
            unique: (value: string, vm: any) => {
                return isUnique(value, vm.id)
            }
        }
    }
};