




































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { VectorImportParamValidations } from '@/constants/validations/vector-import-param.validation';
import { BaseFormValidations } from '@/components/base-form-validations/BaseFormValidation';
import { IVectorParamValue } from '@/interfaces/runPricingModel/parameters/vectorParamValue.interface';

@Component({
  mixins: [validationMixin],
  validations: VectorImportParamValidations
})
export default class VectorParamImportModal extends BaseFormValidations<{
  value: string | null;
}> {
  // properties
  @Prop({ required: true }) range: number[] | null;
  public form = this.getEmptyForm();

  public constructor() {
    super();
  }

  protected getEmptyForm(): { value: string | null } {
    return {
      value: this.range ? this.range.join(',') : null
    };
  }

  @Emit('close')
  public onClose(): void {
    return;
  }

  @Emit('submit')
  public onSubmit(): IVectorParamValue {
    return {
      segments: null,
      rangeValue: this.form.value
        ? this.form.value.split(',').map((v) => Number.parseFloat(v))
        : null
    };
  }

  public reset(): void {
    this.form = this.getEmptyForm();
    this.$v.$reset();
  }
}
