














































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  IRunPricingColumn,
  RunPricingDataType
} from '@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface';
import { IParamData } from '@/interfaces/runPricingModel/parameters/commonParam.interface';
import { NameValuePair } from '@/models/NameValuePair';
import _ from 'lodash';

@Component({})
export default class TableParam extends Vue {
  // properties
  @Prop({ required: true }) column: IRunPricingColumn;

  @Prop({
    required: false,
    default: () => {
      return {
        value: [],
        type: RunPricingDataType.TABLE,
        columnName: '',
        isValid: true
      };
    }
  })
  data: IParamData;

  public runPricingDataTypes: any = RunPricingDataType;
  public tableParamCollapse: boolean = false;
  public isTableValid: boolean = true;
  public errorMessage: string = '';

  public mounted(): void {
    const paramValues: NameValuePair[] = [];
  
    this.column.tableColumns.forEach((column: IRunPricingColumn) => {
      if (!this.data.value) {
        this.data.value = [];
      }

      const paramValue: NameValuePair = this.data.value.find(
        (item: NameValuePair) => item.name === column.columnName
      );

      paramValues.push(
        paramValue ? paramValue : new NameValuePair(column.columnName, null)
      );
    });
    if (paramValues.findIndex((val) => val.value != null) !== -1) {
      this.tableParamCollapse = true;
    }
    this.data.value = paramValues;
  }

  @Emit('receiveParamValue')
  public receiveParamValue(value: IParamData): IParamData {
    return value;
  }

  public update(): void {
    this.data.columnName = this.column.columnName;
    this.data.columnType = this.column.type;
    this.data.inputValue = this.data.value;
    this.data.isValid = (!this.data.value[0].value && !this.data.value[1].value) ||
      !this.data.value.some(
        (v: any, index: number) =>
          !this.checkIfValid(v.value, this.column.tableColumns[index].dataType)
      );
    this.isValid();
    
    this.receiveParamValue(this.data);
  }

  public isValid():boolean{
    this.isTableValid = true;
    this.errorMessage = '';
    if((this.data.value[0].value && !this.data.value[1].value) || !this.data.value[0].value && this.data.value[1].value){
      this.errorMessage = "Count of elements should be equal in both cells";
      return this.isTableValid = false;
    }

    if(this.data.value[0].value && this.data.value[1].value){
      if(this.data.value[0].value.split(',').length != this.data.value[1].value.split(',').length){
        this.errorMessage = "Count of elements should be equal in both cells";
        return this.isTableValid = false;
      }
    }
    if(!this.data.isValid){
      this.errorMessage = "Must contain comma-separated numeric values with same number of descending values across table fields";
      return this.isTableValid = false;
    }

    return this.isTableValid;
  }

  private isListInDescendingOrder(value: any): boolean {
    const arr = value.split(',');
    for (let i = 0; i < arr.length; i++) {
      if (+arr[i + 1] > +arr[i]) {
        return false;
      }
    }
    return true;
  }

  public isListOfInts(value: any): boolean {
    return value && value.match(/^(\d+(?:[ \t]*,[ \t]*\d+)*)?$/) != null;
  }

  public isListOfFloats(value: any): boolean {
    return (
      value &&
      value.match(/^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/) != null
    );
  }

  private checkIfValid(value: any, type: RunPricingDataType): boolean {
      switch (type) {
      case RunPricingDataType.LIST_INT:
        return this.isListOfInts(value) && 
      (this.isListInDescendingOrder(value) || this.data.columnName === 'TBATable');
      case RunPricingDataType.LIST_FLOAT:
        return this.isListOfFloats(value) && 
      (this.isListInDescendingOrder(value) || this.data.columnName === 'TBATable');
      default:
        return true;
    }
  }
}
