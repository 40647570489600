var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"dialog"}},[_c('c-modal',{staticClass:"no-footer modal-full-size",attrs:{"show":true,"closeOnBackdrop":false,"centered":true,"size":"xl"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"modal-title"},[_vm._v("Subset rule")]),_c('div',{staticClass:"d-flex ml-4 align-items-center"},[_c('label',{staticClass:"mr-4 mb-3",class:{
            'invalid-data':
              _vm.$v.form.name.$dirty &&
              _vm.$v.form.name.$invalid &&
              !_vm.$v.form.name.$pending,
          }},[_vm._v("Name *")]),_c('c-input',{attrs:{"isValid":!(_vm.$v.form.name.$dirty && _vm.$v.form.name.$invalid && !_vm.$v.form.name.$pending),"lazy":false,"value":_vm.$v.form.name.$model,"placeholder":"Enter the name","addLabelClasses":{
            'invalid-data':
              _vm.$v.form.name.$dirty &&
              _vm.$v.form.name.$invalid &&
              !_vm.$v.form.name.$pending,
          }},on:{"change":_vm.updateName}}),(_vm.$v.form.name.$dirty && !_vm.$v.form.name.$pending)?_c('div',{staticClass:"form-group ml-2"},[(!_vm.$v.form.name.required)?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Name is required. ")]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Name must have at most "+_vm._s(_vm.$v.form.name.$params.maxLength.max)+" letters. ")]):_vm._e(),(!_vm.$v.form.name.unique)?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Name already exists. ")]):_vm._e()]):_vm._e()],1),_c('c-button',{staticClass:"btn-primary mr-3 submit-button",on:{"click":_vm.submit}},[_vm._v("Save")]),_c('c-button-close',{staticClass:"text-white",on:{"click":_vm.onClose}})]},proxy:true},{key:"default",fn:function(){return [(_vm.$v.form)?_c('c-form',{staticClass:"form-container"},[_c('c-row',{staticClass:"d-flex pb-4 flex-wrap"},[_c('c-col',{staticClass:"col-lg",attrs:{"md":"12","sm":"12"}},[(_vm.form.rule)?_c('div',[_c('json-query-builder',{ref:"queryBuilder",attrs:{"options":_vm.queryOptions},on:{"input":function($event){return _vm.isControlValid('rule')}},model:{value:(_vm.form.rule),callback:function ($$v) {_vm.$set(_vm.form, "rule", $$v)},expression:"form.rule"}})],1):_vm._e()])],1)],1):_vm._e()]},proxy:true},{key:"footer",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }