import { IUserRoles } from "@/interfaces/userRoles/userRoles";
import httpClient from "@/plugins/httpClient";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";

@Module({
    name: 'permissionsModule',
    namespaced: true,
    dynamic: true,
    store
})
export default class PermissionsModule extends VuexModule {
    public userRoles: IUserRoles | null = null;
    public isLoading: boolean = false;

    @Mutation
    public setUserPermissions(userPermissions: IUserRoles): void {
        this.userRoles = userPermissions;
    }

    @Mutation
    public setIsLoading(isLoad: boolean): void {
        this.isLoading = isLoad;
    }

    @Action({rawError: true})
    public async getUserRoles(): Promise<IUserRoles> {
        this.setIsLoading(true);
        const propertiesMetadata: IUserRoles = await httpClient.get<IUserRoles>(`/Permissionss/Roles`);
        this.setUserPermissions(propertiesMetadata);
        this.setIsLoading(false);

        return propertiesMetadata;
    }

    public get isPermissionsLoaded(): boolean {
        return this.userRoles === null ? false : true;
    }

    public get isLoadingPermissions(): boolean {
        return this.isLoading;
    }

    public get getCurrentUserRoles(): IUserRoles | null {
        return this.userRoles;
    }
}
