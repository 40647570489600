



















import Vue from 'vue';
import TheHeader from './TheHeader.vue';
import TheFooter from './TheFooter.vue';
import Loader from '@/components/ui/Loader.vue';
import { CContainer, CWrapper } from '@coreui/vue';

export default Vue.extend({
  name: 'the-container',
  components: {
    'the-header': TheHeader,
    'the-footer': TheFooter,
    'c-wrapper': CWrapper,
    'c-container': CContainer
  }
});
