import { IImportTemplate } from '@/interfaces/importTemplates/import-template.interface';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import httpClient from '@/plugins/httpClient';

@Module({
    name: 'importTemplateModule',
    namespaced: true,
    dynamic: true,
    store
})
export default class ImportTemplateModule extends VuexModule {
    public importTemplates: IImportTemplate[] = [];

    @Mutation
    public setImportTemplates(importTemplate: IImportTemplate[]): void {
        this.importTemplates = importTemplate;
    }

    @Action({ commit: 'setImportTemplates' })
    public async getImportTemplates(): Promise<IImportTemplate[]> {
        return httpClient.get<IImportTemplate[]>('/importTemplates');
    }

    @Action
    public async getImportTemplate(id: number): Promise<IImportTemplate> {
        return httpClient.get<IImportTemplate>(`/importTemplates/${id}`);
    }
}
