var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"json-query-builder"},[_c('json-query-group',{ref:"group",attrs:{"currentQuery":_vm.value,"options":_vm.options},scopedSlots:_vm._u([{key:"field",fn:function(ref){
var rule = ref.rule;
var options = ref.options;
return [_vm._t("field",null,{"rule":rule,"options":options})]}},{key:"ruleOperator",fn:function(ref){
var rule = ref.rule;
var options = ref.options;
return [_vm._t("ruleOperator",null,{"rule":rule,"options":options})]}},{key:"number",fn:function(ref){
var rule = ref.rule;
return [_vm._t("number",null,{"rule":rule})]}},{key:"date",fn:function(ref){
var rule = ref.rule;
return [_vm._t("date",null,{"rule":rule})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }