import { IParamData } from "@/interfaces/runPricingModel/parameters/commonParam.interface";
import { RunPricingDataType } from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";
import moment from "moment";

export class ParametersService {
    public static FormatDateParameters(parameters: IParamData[], format: string = "LL", formatValue: boolean = true): IParamData[] {
        parameters = parameters.map((x) => {
            if (x.type === RunPricingDataType.DT) {
                if (formatValue) {
                    x.value = moment(x.value).format(format);
                    x.inputValue = x.value;
                } else {
                    x.inputValue = moment(x.inputValue).format(format);
                }
            }

            return x;
        });

        return parameters;
    }
};
