import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IEntityMetadata } from '@/interfaces/entity-metadata.interface';
import store from '@/store';
import httpClient from '@/plugins/httpClient';
import { ILoanRawPropertyMetadata } from '@/interfaces/loanRaws/loan-raw-property-metadata.interface';

@Module({
    name: 'entityDataModule',
    namespaced: true,
    dynamic: true,
    store
})
export default class EntityDataModule extends VuexModule {
    public loanRawPropertiesMetadata: ILoanRawPropertyMetadata[] | null = null;

    @Mutation
    public setLoanRawMetadata(propertiesMetadata: ILoanRawPropertyMetadata[]): void {
        this.loanRawPropertiesMetadata = propertiesMetadata;
    }

    @Action
    public async getLoanRawMetadata(): Promise<ILoanRawPropertyMetadata[]> {
        const propertiesMetadata: ILoanRawPropertyMetadata[] = await httpClient.get<ILoanRawPropertyMetadata[]>('/loanRaws/metadata');
        this.setLoanRawMetadata(propertiesMetadata);

        return propertiesMetadata;
    }
}
