import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';

export const DealSummaryValidations = {
  form: {
    name: {
      required,
      maxLength: maxLength(55)
    },
    revisionName: {
      required,
      maxLength: maxLength(55)
    },
    sellerName: {},
    saleManagedBy: {},
    collateralType: {},
    competitiveNegotiated: {},
    bidDate: {},
    settleDate: {},
    cutoffDate: {},
    isDepositRequired: {},
    depositAmountRequired: {
      required: requiredIf((form) => {
        return form.isDepositRequired && !form.depositAmountRequired;
      }),
      maxLength: maxLength(55)
    },
    key: {}
  }
};