import { IExecution, IRunPricingModel } from '@/interfaces/runPricingModel/runPricingModel.interface';
import httpClient from '@/plugins/httpClient';

class RunPricingService {
    public runPricingModel(model: IRunPricingModel, runPricing: Boolean): Promise<any> {
        model.runPricing = runPricing;
        return httpClient.post<any>(`/runPricing`, model)
    }

    public runPropensityModel(model: IRunPricingModel): Promise<any> {
        return httpClient.post<any>(`/runPricing/propensity`, model)
    }

    public runExistingExecution(pricingModelExecutionId: number): Promise<any> {
        var model = {
            pricingModelExecutionId: pricingModelExecutionId
        }
        return httpClient.post<any>(`/runPricing/RunByExecution`, model)
    }

    public deleteExecution(pricingModelExecutionId: number): Promise<any> {
        var model = {
            pricingModelExecutionId: pricingModelExecutionId
        }
        return httpClient.post<any>(`/runPricing/DeleteExecution`, model)
    }

    public getExecution(id: number): Promise<any> {

        return httpClient.get<IExecution>(`/runPricing/execution/${id}`);
    }
}

export default new RunPricingService();