import { maxLength, requiredIf } from 'vuelidate/lib/validators';

export const ImportTemplateSummaryValidations = {
  form: {
    existingTemplateName: {
      required: requiredIf((form) => {
        return !form.newTemplateChosen;
      }),
      maxLength: maxLength(55)
    },
    newTemplateName: {
      required: requiredIf((form) => {
        return form.newTemplateChosen;
      }),
      maxLength: maxLength(55)
    },
    newTemplateChosen: {}
  }
};