var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"dialog"}},[_c('c-modal',{staticClass:"no-footer",attrs:{"show":true,"closeOnBackdrop":false,"centered":true,"size":"xl"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"modal-title"},[_vm._v("Segments")]),_c('c-button-close',{staticClass:"text-white",on:{"click":_vm.onClose}})]},proxy:true},{key:"default",fn:function(){return [(_vm.form)?_c('c-form',{staticClass:"form-container"},[_c('draggable',{model:{value:(_vm.segments),callback:function ($$v) {_vm.segments=$$v},expression:"segments"}},_vm._l((_vm.segments),function(segment,index){return _c('c-row',{key:index,staticClass:"justify-content-left align-items-center odd-row",class:{
              'odd-row': index % 2 === 0,
              'even-row': index % 2 !== 0,
            }},[_c('c-col',{staticClass:"12"},[_c('c-row',{staticClass:"ml-0 mt-2"},[_c('c-col',{staticClass:"col-border",attrs:{"col":"3"}},[_c('c-row',{staticClass:"mt-2"},[_c('c-col',{staticClass:"d-flex segment-parameter col-border",attrs:{"col":"2"}},[_c('img',{staticClass:"w-50 h-50 align-self-center",attrs:{"src":"/icons/drag_indicator.svg"}})]),_c('c-col',{staticClass:"ml-3",attrs:{"col":"9"}},[_c('c-row',[_c('c-col',{staticClass:"segment-parameter",attrs:{"col":"4"}},[_c('div',{staticClass:"text-center"},[_c('c-input',{class:{
                                'is-invalid':
                                  _vm.$v.form.$each.$iter[index].vectorLength
                                    .$invalid,
                              },attrs:{"isValid":_vm.isControlValid('vectorLength', index),"lazy":false,"label":"Length","placeholder":"","disabled":!_vm.isSegmentProcessed,"addInputClasses":['p-1'],"value":_vm.$v.form.$each.$iter[index].vectorLength.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.form.$each.$iter[index].vectorLength, "$model", $event)}}})],1)]),_c('c-col',{staticClass:"segment-parameter",attrs:{"col":"4"}},[_c('div',{staticClass:"text-center"},[_c('c-input',{class:{
                                'is-invalid':
                                  _vm.$v.form.$each.$iter[index].start.$invalid,
                              },attrs:{"isValid":_vm.isControlValid('start', index),"lazy":false,"label":"Start","disabled":!_vm.isSegmentProcessed,"placeholder":"","value":_vm.$v.form.$each.$iter[index].start.$model,"addInputClasses":['p-1']},on:{"update:value":function($event){return _vm.$set(_vm.$v.form.$each.$iter[index].start, "$model", $event)}}})],1)]),_c('c-col',{staticClass:"segment-parameter",attrs:{"col":"4"}},[_c('div',{staticClass:"text-center"},[_c('c-input',{class:{
                                'is-invalid':
                                  _vm.$v.form.$each.$iter[index].end.$invalid,
                              },attrs:{"isValid":_vm.isControlValid('end', index),"disabled":!_vm.isSegmentProcessed,"lazy":false,"label":"End","placeholder":"","value":_vm.$v.form.$each.$iter[index].end.$model,"addInputClasses":['p-1']},on:{"update:value":function($event){return _vm.$set(_vm.$v.form.$each.$iter[index].end, "$model", $event)}}})],1)]),_c('c-col',{attrs:{"col":"12"}},[(
                              _vm.$v.form.$each.$iter[index].vectorLength.$dirty
                            )?_c('div',[(
                                !_vm.$v.form.$each.$iter[index].vectorLength
                                  .numeric ||
                                !_vm.$v.form.$each.$iter[index].vectorLength
                                  .minValue ||
                                !_vm.$v.form.$each.$iter[index].vectorLength
                                  .maxValue
                              )?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Vector length must be numeric with min 1 and max 1000 ")]):_vm._e()]):_vm._e(),(_vm.$v.form.$each.$iter[index].start.$dirty)?_c('div',[(
                                !_vm.$v.form.$each.$iter[index].start.decimal ||
                                !_vm.$v.form.$each.$iter[index].start.minValue ||
                                !_vm.$v.form.$each.$iter[index].start.maxValue
                              )?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Start must be decimal with min -1000000000 and max 1000000000 ")]):_vm._e()]):_vm._e(),(_vm.$v.form.$each.$iter[index].end.$dirty)?_c('div',[(
                                !_vm.$v.form.$each.$iter[index].end.decimal ||
                                !_vm.$v.form.$each.$iter[index].end.minValue ||
                                !_vm.$v.form.$each.$iter[index].end.maxValue
                              )?_c('div',{staticClass:"validation-error-message"},[_vm._v(" End must be decimal with min -1000000000 and max 1000000000 ")]):_vm._e()]):_vm._e()])],1)],1)],1)],1),_c('c-col',{staticClass:"d-flex align-items-center",attrs:{"col":"9"}},[_c('div',{staticClass:"flex-grow-1 secondary-content-color"},[_vm._v(" Segment: [ "),_c('segment-output',{attrs:{"segment":_vm.$v.form.$each.$iter[index]},on:{"update:segment":function($event){return _vm.$set(_vm.$v.form.$each.$iter, index, $event)},"segment-processing":_vm.isSegmentProcessing}}),_vm._v(" ] ")],1),_c('div',{staticClass:"btn-group"},[_c('c-button',{directives:[{name:"confirm-operation",rawName:"v-confirm-operation",value:({
                        operation: 'delete',
                        confirmOperationDataToShow: [
                          {
                            label: 'Segment',
                          } ],
                      }),expression:"{\n                        operation: 'delete',\n                        confirmOperationDataToShow: [\n                          {\n                            label: 'Segment',\n                          },\n                        ],\n                      }"}],staticClass:"btn-outline-danger custom-btn-rounded",on:{"confirm-operation-submit":function($event){return _vm.removeSegment(segment)}}},[_c('img',{attrs:{"src":"/icons/remove.svg"}})])],1)])],1)],1)],1)}),1),_c('c-row',{staticClass:"justify-content-end mt-3"},[_c('c-button',{staticClass:"btn-primary mr-3",attrs:{"disabled":_vm.$v.form.$invalid},on:{"click":_vm.addSegment}},[_vm._v("+ Add New Segment")]),_c('c-button',{staticClass:"btn-primary mr-3",attrs:{"disabled":_vm.$v.form.$invalid || _vm.segments.length === 0},on:{"click":_vm.onSubmit}},[_vm._v("Save")])],1)],1):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }