export function validPromise(formName) {
    return new Promise((resolve, reject) => {
        const unwatch = this.$watch(
          () => !this.$v[formName].$invalid,
          (isValid) => {
            if (isValid) {
              if(typeof unwatch === 'function') {
                unwatch();
              }
              resolve();
            }
          },
          { immediate: true }
        );
      });
}