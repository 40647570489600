














































































































































































































































































































































































































































































































































































































































import { ICandidateAddress } from '@/interfaces/standardize-addresses/candidate-address.interface';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class StandardizedAddressResultModal extends Vue {
  // Props
  @Prop({ required: false, default: true })
  public show: boolean;

  @Prop({ required: true, default: false })
  public standardizedAddress: ICandidateAddress;

  @Emit('close')
  public close(): void {
    return;
  }
}
