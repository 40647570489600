import { IScenarioDetails } from '@/interfaces/runPricingModel/scenarioDetails.interface';
import { IScenario } from '@/interfaces/runPricingModel/scenario.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import httpClient from '@/plugins/httpClient';
import { QueryBuilderService } from './query-builder.service';
import { IVectorSegmentParam } from '@/interfaces/runPricingModel/parameters/vectorSegmentParam.interface';

class ScenarioService {
  public getScenarios(searchState: FiltrationState): Promise<PaginatedResult<IScenario>> {
    const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

    return httpClient.get<PaginatedResult<IScenario>>('/scenarios', { params: searchParams });
  }

  public getById(id: number): Promise<IScenarioDetails> {
    return httpClient.get<IScenarioDetails>(`/scenarios/${id}`);
  }

  public create(scenario: IScenarioDetails): Promise<any> {
    return httpClient.post<any>(`/scenarios`, scenario)
  }

  public async update(id: number, scenario: IScenarioDetails): Promise<number> {
    return httpClient.put<number>(`/scenarios/${id}`, scenario);
  }

  public async remove(id: number): Promise<object> {
    return httpClient.delete<object>(`/scenarios/${id}`);
  }

  public generateSegment(segment: IVectorSegmentParam): Promise<any> {
    return httpClient.post<any>(`/scenarios/generateSegment`, segment)
  }

  public getExistScenarioOrDefault(name: string, id?: number): Promise<IScenario> {
    let url: string = `/scenarios/exists?name=${name}`;

    if (id) {
      url += `&id=${id}`;
    }
    return httpClient.get<IScenario>(url, { headers: { useGlobalLoader: false, useToastNotification: false } });
  }
}

export default new ScenarioService();