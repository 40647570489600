import { ISubsetRule } from '@/interfaces/runPricingModel/subsetRule.interface';
import { ISubsetRuleDetails } from '@/interfaces/runPricingModel/subsetRuleDetails.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import httpClient from '@/plugins/httpClient';
import { QueryBuilderService } from './query-builder.service';

class SubsetRuleService {
    public getSubsetRules(searchState: FiltrationState): Promise<PaginatedResult<ISubsetRule>> {
        const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

        return httpClient.get<PaginatedResult<ISubsetRule>>('/subsetRules', { params: searchParams });
    }

    public getById(id: number): Promise<ISubsetRuleDetails> {
        return httpClient.get<ISubsetRuleDetails>(`/subsetRules/${id}`);
    }

    public create(subsetRule: ISubsetRuleDetails): Promise<number> {
        return httpClient.post<number>(`/subsetRules`, subsetRule)
    }

    public async update(id: number, subsetRule: ISubsetRuleDetails): Promise<number> {
        return httpClient.put<number>(`/subsetRules/${id}`, subsetRule);
    }

    public getExistSubsetRuleOrDefault(name: string, id?: number): Promise<ISubsetRule> {
        let url: string = `/subsetRules/exists?name=${name}`;

        if (id) {
            url += `&id=${id}`;
        }
        return httpClient.get<ISubsetRule>(url, { headers: { useGlobalLoader: false, useToastNotification: false } });
    }

    public copy(id: number): Promise<ISubsetRule> {
        return httpClient.post<ISubsetRule>(`/subsetRules/${id}/copy`)
    }
}

export default new SubsetRuleService();