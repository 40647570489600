






























































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { LocalRuleValidations } from "@/constants/validations/local-rule.validation";
import { ILocalRuleDetails } from "@/interfaces/runPricingModel/localRuleDetails.interface";
import { ISubsetRule } from "@/interfaces/runPricingModel/subsetRule.interface";
import { validationMixin } from "vuelidate";
import { withPopper } from "@/plugins/popover";
import { FiltrationState } from "@/models/filtrationState";
import { maxItemsInDropDown } from "@/constants/stateConstants";
import { PaginatedResult } from "@/models/paginatedResult";
import SubsetRuleService from "@/services/subset-rule.service";
import LocalRuleService from "@/services/local-rule.service";
import ParameterAdjustmentService from "@/services/parameter-adjustment.service";
import { getModule } from "vuex-module-decorators";
import RunPricingModelModule from "@/store/modules/runPricingModel.module";
import { RunPricingModelState } from "@/models/runPricingModelState";
import { checkIfValid } from "@/utils/validators";
import { IParameterAdjustment } from "@/interfaces/runPricingModel/parameterAdjustment.interface";
import { Dictionary } from "vue-router/types/router";
import { ILocalRule } from "@/interfaces/runPricingModel/localRule.interface";
import { validPromise } from "@/utils/validPromise";
import { RPMPagesType } from "@/enums/rpm-pages-type";

@Component({
  mixins: [validationMixin],
  validations: LocalRuleValidations,
  methods: {
    updateName: function (this: any, e: any) {
      this.form.name = e;
      this.$v.form.$touch();
    },
  },
  data() {
    return {
      isRouteLeaving: false,
      nextRouteName: String,
      isRedirectToPrevious: false,
      nextRoute: Function,
      isGoingForward: false
    };
  },
  async beforeRouteLeave(to, from, next) {
    this.$data.nextRouteName = to.name;
    await this.formChanged();
    this.$data.isRouteLeaving = this.isDirty;
    if (this.$data.isRedirectToPrevious || this.$data.isGoingForward) {
      this.$data.isRouteLeaving = false;
    }

    if (!this.$data.isRouteLeaving) {
      next();
    }

    this.$data.isRedirectToPrevious = false;
    this.$data.isGoingForward = false;
    this.$data.nextRoute = next;
  },
})
export default class LocalRule extends Vue {
  // properties
  public popover = withPopper;

  public isSaveStateAfterSubmit: boolean = false;
  public subsetRules: ISubsetRule[] = [];
  public parameterAdjustments: IParameterAdjustment[] = [];

  public subsetRuleModalOptions: any = {
    show: false,
    subsetRuleId: null,
  };

  public defaultSubSetRule: any = null;
  public isLocalRuleUpdated: boolean = false;

  protected form: ILocalRuleDetails = {
    id: null,
    name: "",
    description: "",
    isAlwaysTrueSubsetRules: false,
    subsetRuleId: null,
    parameterAdjustmentId: null,
    sequence: 0
  };

  protected pristineForm: ILocalRuleDetails = {
    id: null,
    name: "",
    description: "",
    isAlwaysTrueSubsetRules: false,
    subsetRuleId: null,
    parameterAdjustmentId: null,
    sequence: 0
  };
  public isDirty: boolean = false;

  private numberOfSubsetRulesLoaded: number = 0;
  private numberOfParameterAdjustmentsLoaded: number = 0;
  private runPricingModelModule: RunPricingModelModule;

  public constructor() {
    super();
    this.runPricingModelModule = getModule(RunPricingModelModule);
  }

  public created(): void {
    this.loadLocalRuleModelState();
    this.searchSubsetRules(null, false, 0, false);
    this.searchParameterAdjustments(null, false, 0, false);
  }

  protected getEmptyForm(): ILocalRuleDetails {
    return {
      id: null,
      name: "",
      description: "",
      isAlwaysTrueSubsetRules: false,
      subsetRuleId: null,
      parameterAdjustmentId: null,
      sequence: 0,
    };
  }

  public isControlValid(fieldName: string): boolean {
    return checkIfValid(this.$v.form, fieldName);
  }

  public validate(): void {
    this.$v.$touch();
    this.$v.form.$touch();
    this.$v.form.$pending
  }

  public editParameterAdjustment(parameterAdjustmentId: number): void {
    this.saveState();
    this.$data.isGoingForward = true;
    this.$router.push({
      name: "ParameterAdjustment",
      params: { id: parameterAdjustmentId.toString() },
    });
  }

  public createParameterAdjustment(): void {
    this.saveState();
    this.$data.isGoingForward = true;
    this.$router.push({
      name: "ParameterAdjustment",
    });
  }

  public openSubsetRuleModal(subsetRuleId?: number): void {
    this.subsetRuleModalOptions.show = true;
    this.subsetRuleModalOptions.subsetRuleId = subsetRuleId;
  }

  public closeSubsetRuleModal(): void {
    this.subsetRuleModalOptions = {
      show: false,
      subsetRuleId: null,
    };
  }

  public submitSubsetRuleModal(subsetRule: ISubsetRule): void {
    this.form.subsetRuleId = subsetRule.id;
    this.form.isAlwaysTrueSubsetRules = false;
    this.searchSubsetRules(null, false, 0, false);
    this.closeSubsetRuleModal();
  }

  @Watch("form", { deep: true })
  public async formChanged() {
    this.isDirty =
      await this.runPricingModelModule.checkFormIsDirty({ 
        form: this.form,
        pristineForm: this.pristineForm,
        type: RPMPagesType.LocalRule
      });
  }

  public searchSubsetRules(
    search: string | null,
    loading: boolean,
    skipCount: number,
    isLoadMore: boolean
  ): void {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }

    this.loadSubsetRules(state, isLoadMore);
  }

  public searchParameterAdjustments(
    search: string | null,
    loading: boolean,
    skipCount: number,
    isLoadMore: boolean
  ): void {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }

    this.loadParameterAdjustments(state, isLoadMore);
  }

  public submit(): void {
    this.validate();

    if (!this.$v.form.$invalid) {
      this.form.id ? this.update() : this.create();
    } else {
      this.$data.isRouteLeaving = false;
    }
  }

  public onSubsetRuleChange(item?: any): void {
    this.form.subsetRuleId = item;
    if (item === undefined) {
      this.form.isAlwaysTrueSubsetRules = true;
    } else {
      this.form.isAlwaysTrueSubsetRules = false;
      this.isControlValid("subsetRuleId");

      if (this.$v.form.subsetRuleId) {
        this.$v.form.subsetRuleId.$touch();
      }
    }
  }

  public onParameterAdjustmentChange(item?: number): void {
    this.form.parameterAdjustmentId = item;
    this.isControlValid("parameterAdjustmentId");

    if (this.$v.form.parameterAdjustmentId) {
      this.$v.form.parameterAdjustmentId.$touch();
    }
  }

  public create(): void {
    LocalRuleService.create(this.form).then((ruleId: number) => {
      this.form.id = ruleId;
      this.pristineForm = this.form;
      Vue.$toast.clear();
      Vue.$toast.success(`Local Rule ${this.form.name} created`);
      this.$data.isRedirectToPrevious = true;
      this.returnToRunPricingModel({ redirectToPrevious: true });
    });
  }

  public update(): void {
    LocalRuleService.update(this.form.id as number, this.form).then(() => {
      Vue.$toast.clear();
      Vue.$toast.success(`Local Rule ${this.form.name} updated`);
      this.pristineForm = this.form;
      this.isLocalRuleUpdated = true;
      this.$data.isRedirectToPrevious = true;
      this.returnToRunPricingModel({ redirectToPrevious: true });
    });
  }

  public copySubsetRule(id: number): void {
    SubsetRuleService.copy(id).then((subsetRule: ISubsetRule) => {
      this.openSubsetRuleModal(subsetRule.id);
      this.subsetRules = [subsetRule].concat(this.subsetRules);
      this.form.subsetRuleId = subsetRule.id;
    });
  }

  public copyParameterAdjustment(id: number): void {
    ParameterAdjustmentService.copy(id).then((parameterAdjustmentId: number) =>
      this.editParameterAdjustment(parameterAdjustmentId)
    );
  }

  public returnToRunPricingModel({
    item = "RunPricingModel",
    save = true,
    redirectToPrevious = false,
  } = {}): void {
    let routerParams: Dictionary<string> | undefined;
    let routeId: any = "";

    if (
      this.runPricingModelModule.runPricingModelState &&
      this.runPricingModelModule.runPricingModelState.selectedScenario &&
      this.runPricingModelModule.runPricingModelState.selectedScenario.id
    ) {
      routeId =
        this.runPricingModelModule.runPricingModelState.selectedScenario.id.toString();
    }

    if (this.$data.nextRouteName === "ParameterAdjustment") {
      routeId = this.form.parameterAdjustmentId;
    }
    routerParams = {
      id: routeId,
    };

    if (save && this.isSaveStateAfterSubmit) {
      this.saveState();
      this.isSaveStateAfterSubmit = false;
    } else {
      let state = this.runPricingModelModule.runPricingModelState;
      if (state) {
        if(!this.isLocalRuleUpdated) {
          if(state.selectedScenario && !state.selectedScenario.localRules.some(x => x.id === this.pristineForm.id)) {
            if(JSON.stringify(this.pristineForm) !== JSON.stringify(this.getEmptyForm())) {
              state.selectedLocalRule = this.pristineForm
            } 
          }
          this.isLocalRuleUpdated = false;
        }
        if (state.selectedScenario) {
          state.selectedScenario.localRules =
            state.selectedScenario.localRules.map((x) =>
              x.id === this.form.id ? (x = this.pristineForm) : x
            );
        }
        state.localRule = this.getEmptyForm();
        this.runPricingModelModule.updateState(state);
      }
    }

    if (this.$data.nextRoute && !redirectToPrevious) {
      this.$data.nextRoute();
      return;
    }

    this.$router.push({
      name:
        typeof this.$data.nextRouteName === "string"
          ? this.$data.nextRouteName
          : item,
      params: routerParams,
    });
  }

  private async loadLocalRuleModelState(): Promise<void> {
    const lastLocalRule: ILocalRule | null = this.getLastLocalRuleFromStore();
    if (
      (lastLocalRule?.id || this.$route.params.id) &&
      !(lastLocalRule && +this.$route.params.id === lastLocalRule?.id)
    ) {
      this.loadLocalRule(
        +this.$route.params.id
          ? +this.$route.params.id
          : <number>lastLocalRule?.id
      );
    } else {
      if (
        lastLocalRule &&
        (+this.$route.params.id === lastLocalRule?.id || !lastLocalRule?.id)
      ) {
        if(lastLocalRule.id) {
          this.pristineForm = JSON.parse(JSON.stringify(lastLocalRule));
        }
        if (lastLocalRule.isAlwaysTrueSubsetRules) {
          this.defaultSubSetRule = { id: 0, name: "Select All" };
        }
        this.form = lastLocalRule;
      } else {
        this.pristineForm = this.getEmptyForm();
        this.form = this.getEmptyForm();
      }
    }

    await validPromise.call(this, 'form');
  }

  private getLastLocalRuleFromStore(): ILocalRuleDetails | null {
    return <ILocalRule>(
      this.runPricingModelModule?.runPricingModelState?.localRule
    );
  }

  private loadLocalRule(id: number): void {
    LocalRuleService.getById(id).then((value) => {
      this.pristineForm = JSON.parse(JSON.stringify(value));
      if (value.isAlwaysTrueSubsetRules) {
        this.defaultSubSetRule = { id: 0, name: "Select All" };
      }
      this.form = value;
    });
  }

  private loadParameterAdjustments(
    state: FiltrationState,
    isLoadMore: boolean
  ): void {
    ParameterAdjustmentService.getParameterAdjustments(state).then(
      (response: PaginatedResult<IParameterAdjustment>) => {
        if (isLoadMore) {
          for (const parameterAdjustment of response.entities) {
            this.parameterAdjustments.push(parameterAdjustment);
          }
        } else {
          this.parameterAdjustments = response.entities;
        }

        this.numberOfParameterAdjustmentsLoaded += response.entities.length;
        if (response.totalCount <= this.numberOfParameterAdjustmentsLoaded) {
          this.numberOfParameterAdjustmentsLoaded = 0;
        }
      }
    );
  }

  private loadSubsetRules(state: FiltrationState, isLoadMore: boolean): void {
    SubsetRuleService.getSubsetRules(state).then(
      (response: PaginatedResult<ISubsetRule>) => {
        if (isLoadMore) {
          for (const subsetRule of response.entities) {
            this.subsetRules.push(subsetRule);
          }
        } else {
          this.subsetRules = response.entities;
          this.subsetRules.unshift({ name: "Select All"});
        }

        if (this.form?.subsetRuleId) {
          this.defaultSubSetRule = {
            id: this.form?.subsetRuleId,
            name: this.subsetRules?.find(
              (x) => x.id === this?.form.subsetRuleId
            )?.name,
          };
        }

        this.numberOfSubsetRulesLoaded += response.entities.length;
        if (response.totalCount <= this.numberOfSubsetRulesLoaded) {
          this.numberOfSubsetRulesLoaded = 0;
        }
      }
    );
  }

  private saveState(): void {
    let state = this.runPricingModelModule.runPricingModelState;
    if (state) {
      if (!this.isLocalRuleUpdated) {
        state.selectedLocalRule = this.$v.form.$dirty ? this.form : null;
        this.isLocalRuleUpdated = false;
      }
      state.localRule = this.form;
      if (state.selectedScenario) {
        state.selectedScenario.localRules =
          state.selectedScenario.localRules.map((x) =>
            x.id === this.form.id ? (x = this.form) : x
          );
      }
      this.runPricingModelModule.updateState(state);
    }
  }
}
