




















































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { OperationsToConfirm } from '@/models/types/operationToConfirm.type';

@Component({})
export default class ConfirmOperationModal extends Vue {
  @Prop({ required: false })
  public operationProp: OperationsToConfirm;
  @Prop({ required: false })
  public confirmOperationDataToShowProp: { label: string; value: any }[];
  @Prop({ required: false })
  public useCustomMessageProp: boolean;
  @Prop({ required: false })
  public customMessageProp: string;
  @Prop({ required: false })
  public submitButtonTextProp: string;
  @Prop({ required: false })
  public cancelButtonTextProp: string;
  @Prop({ required: false })
  public discardButtonTextProp: string;
  @Prop({ required: false })
  public isDiscardRequiredProp: boolean;
  @Prop({ required: false })
  public disableButtonClose: boolean;
  @Prop({ required: false })
  public isCustomHeaderText: boolean;
  @Prop({ required: false })
  public customHeaderText: string;

  public operation: OperationsToConfirm = null;
  public confirmOperationDataToShow: { label: string; value: any }[] = [];
  public useCustomMessage: boolean = false;
  public customMessage: string;
  public isDiscardRequired: boolean = false;

  public submitButtonText: string = 'Yes';
  public cancelButtonText: string = 'No';
  public discardButtonText: string = 'Discard';

  @Emit('cancel')
  public cancel(): void {
    return;
  }

  @Emit('submit')
  public submit(): void {
    return;
  }

  @Emit('discard')
  public discard(): void {
    return;
  }

  public submitOperation(): void {
    this.submit();
  }

  public discardOperation(): void {
    this.discard();
  }

  public cancelOperation(): void {
    this.cancel();
  }
}
