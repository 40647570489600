<template>
  <div class="row">
    <div class="col-md-12">
      <img
        alt="Pricing Portal"
        class="logo mx-auto d-block"
        src="/images/logo.png"
      />
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class Home extends Vue {}
</script>

<style lang="scss" scoped>
.logo {
  max-width: 100%;
  height: auto;
}
</style>
