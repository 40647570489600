import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import LocalRuleService from '@/services/local-rule.service';

const isUnique = async (value: string | null, currentId?: number) => {
    if (!value || value === '') {
        return true;
    } else {
        const resp = await LocalRuleService.getExistLocalRuleOrDefault(value, currentId);
        return !resp.id || resp.id < 1
    }
}

export const LocalRuleValidations = {
    form: {
        id: {},
        name: {
            required,
            maxLength: maxLength(55),
            unique: (value: string, vm: any) => {
                return isUnique(value, vm.id)
            }
        },
        description: {
        },
        isAlwaysTrueSubsetRules: {
        },
        subsetRuleId: {
            required: requiredIf((form) => {
                return !form.isAlwaysTrueSubsetRules && !form.subsetRuleId
            })
        },
        parameterAdjustmentId: {
            required
        }
    }
};