import _ from 'lodash';
import { ValidationEvaluation, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';

export function isDuplicate(value: any, values: any[], fieldName: string): boolean {
    return values.filter((v: any) => v[fieldName] === value[fieldName]).length > 1;
}

export function checkIfValid(form: any, fieldName: string, validOnSumit: boolean = true): boolean {
    let field:
        | (Validation & ValidationProperties<any>)
        | (Validation & ValidationProperties<any> & ValidationEvaluation)
        | undefined = form;
    for (
        let i = 0, fieldNames = fieldName.split('.'), length = fieldNames.length;
        i < length;
        i++
    ) {
        if (field) {
            field = field[fieldNames[i]];
        }
    }

    if (validOnSumit) {
        return (field && (!field.$invalid || !field.$dirty)) === true;
    } else {
        if (!field || !field.$dirty) {
            return false;
        }
        return !field.$invalid;
    }
}

export function validateFieldInArray(form: any, fieldName: string, index: number): boolean {
    const isValid: boolean = checkIfValidInArray(form, fieldName, index);

    if (form.$each && form.$each!.$iter[index]) {
        form.$each!.$iter[index]![fieldName].$touch();
    }

    return isValid;
}

export function checkIfValidInArray(form: any, fieldName: string, index: number): boolean {
    if (form.$each) {
        const model = form.$each.$iter[index];
        if (!model || !model[fieldName]) {
            return false;
        }
        return !model[fieldName].$invalid;
    }

    return false;
}