import { IGlobalRule } from '@/interfaces/runPricingModel/globalRule.interface';
import { IScenario } from '@/interfaces/runPricingModel/scenario.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import httpClient from '@/plugins/httpClient';
import { QueryBuilderService } from './query-builder.service';

class GlobalRuleService {
  public getGlobalRules(searchState: FiltrationState): Promise<PaginatedResult<IGlobalRule>> {
    const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);

    return httpClient.get<PaginatedResult<IGlobalRule>>('/globalRules', { params: searchParams });
  }

  public getById(id: number): Promise<IGlobalRule> {
    return httpClient.get<IGlobalRule>(`/globalRules/${id}`);
  }

  public create(globalRule: IGlobalRule): Promise<any> {
    return httpClient.post<any>(`/globalRules`, globalRule)
  }

  public async update(id: number, globalRule: IGlobalRule): Promise<number> {
    return httpClient.put<number>(`/globalRules/${id}`, globalRule);
  }

  public getExistGlobalRuleOrDefault(name: string, id?: number): Promise<IGlobalRule> {
    let url: string = `/globalRules/exists?name=${name}`;

    if (id) {
      url += `&id=${id}`;
    }
    return httpClient.get<IGlobalRule>(url, { headers: { useGlobalLoader: false, useToastNotification: false } });
  }

  public copy(id: number): Promise<number> {
    return httpClient.post<number>(`/globalRules/${id}/copy`)
  }

  public getParameters(id: number): Promise<string> {
    return httpClient.get<string>(`/globalRules/${id}/parameters`);
  }

}

export default new GlobalRuleService();