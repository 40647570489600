






































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { getModule } from 'vuex-module-decorators';
import EntityDataModule from '@/store/modules/entity-data.module';
import { IImportRule } from '@/interfaces/importRules/import-rule.interface';
import { BaseFormValidations } from '@/components/base-form-validations/BaseFormValidation';
import { ImportRuleValidations } from '@/constants/validations/import-rule.validation';
import { ConditionOperation } from '@/enums/condition-operation';
import { RuleArgumentType } from '@/enums/rule-argument-type';
import { RuleOperation } from '@/enums/rule-operation';
import _ from 'lodash';
import { IMapping } from '@/interfaces/importMapping/mapping.interface';
import { FiltrationState } from '@/models/filtrationState';
import { maxItemsInDropDown } from '@/constants/stateConstants';
import ImportTemplateRuleService from '@/services/import-template-rule.service';
import { PaginatedResult } from '@/models/paginatedResult';
import { ILoanRawPropertyMetadata } from '@/interfaces/loanRaws/loan-raw-property-metadata.interface';
import DataSourceService from '@/services/data-source.service';
import { IDataSource } from '@/interfaces/dataSources/data-source.interface';
import {
  importRuleConditionOperations,
  importRuleOperations
} from '@/constants/importRule.const';
import { ImportRuleCondition } from '@/models/importRuleCondition';
import { ImportRuleArgument } from '@/models/importRuleArgument';
import { decimal, integer } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import { withPopper } from '@/plugins/popover';
import moment from 'moment';

@Component({
  mixins: [validationMixin]
})
export default class MappingRuleModal extends BaseFormValidations<IImportRule> {
  @Prop({ required: true })
  public mappingOption: IMapping;

  public isEditMode: boolean = false;
  public rules: IImportRule[] = [];
  public dataSourceOptions: any[] = [];
  public conditionOperation: any = ConditionOperation;
  public ruleArgumentType: any = RuleArgumentType;
  public ruleOperation: any = RuleOperation;
  public selectedRightOperand: ImportRuleArgument | undefined;
  public selectedAppliesTo: ImportRuleArgument | undefined;
  public conditionRightOperandTag: string = '';

  public ruleConditions: ImportRuleCondition[] = importRuleConditionOperations;
  public ruleOperations: ImportRuleCondition[] = importRuleOperations;

  public dateOperationMeasureUnit: string = 'days';
  public dateOperationArgument2Value: number = 0;

  public conditionRightArguments: ImportRuleArgument[] = [];
  public conditionLeftArguments: ImportRuleArgument[] = [];

  public ruleArgumentTypeOptions: any = [
    { value: RuleArgumentType.Field, label: 'Field' },
    { value: RuleArgumentType.Value, label: 'Value' }
  ];

  public ruleArgument1TypeOptions: any = [
    { value: RuleArgumentType.Field, label: 'Field' },
    { value: RuleArgumentType.Value, label: 'Value' },
    { value: RuleArgumentType.Command, label: 'Command' },
    { value: RuleArgumentType.Nullable, label: 'Set to Null' }
  ];

  public get availableRuleArgument1TypeOptions(): any {
    return this.form.conditionOperation === ConditionOperation.NotMissing
      ? this.ruleArgument1TypeOptions
      : this.ruleArgument1TypeOptions.filter(
          (a: any) => a.value !== RuleArgumentType.Command
        );
  }

  public get conditionRightOperandTags(): any[] {
    return this.form.conditionRightOperand
      ? JSON.parse(this.form.conditionRightOperand).map((item: string) => {
          return { text: item };
        })
      : [];
  }

  public set conditionRightOperandTags(values: any[]) {
    this.form.conditionRightOperand =
      values.length > 0
        ? JSON.stringify(values.map((item: any) => item.text))
        : null;
  }

  public popover = withPopper;

  private entityDataModule: EntityDataModule;
  private rulesChanged: boolean = false;
  private argumentOptions: ImportRuleArgument[] | undefined;

  public constructor() {
    super();

    this.entityDataModule = getModule(EntityDataModule);
    this.argumentOptions = this.entityDataModule?.loanRawPropertiesMetadata?.map(
      (o: ILoanRawPropertyMetadata) => {
        return {
          value: o.name,
          label: o.name,
          type: o.type,
          specificDataSamples: o.specificDataSamples
        };
      }
    );
  }

  protected getEmptyForm(): IImportRule {
    return {
      uuid: this.getUUId(),
      appliesTo: '',
      conditionLeftOperand: '',
      conditionOperation: null,
      conditionRightOperandType: RuleArgumentType.Field,
      conditionRightOperand: null,
      ruleArgument1Type: RuleArgumentType.Field,
      ruleArgument1Value: null,
      ruleOperation: RuleOperation.SetTo,
      ruleArgument2Type: RuleArgumentType.Field,
      ruleArgument2Value: null
    };
  }

  @Validations()
  validations(): any {
    const validationsSet = ImportRuleValidations;

    this.resetControlsValidationsTypeRules([
      validationsSet.form.conditionRightOperand,
      validationsSet.form.ruleArgument1Value,
      validationsSet.form.ruleArgument2Value
    ]);

    if (
      this.selectedRightOperand &&
      this.form.conditionRightOperandType === RuleArgumentType.Value
    ) {
      this.updateControlValidationsByType(
        validationsSet.form.conditionRightOperand,
        this.selectedRightOperand.type
      );
    }

    if (this.selectedAppliesTo) {
      if (this.form.ruleArgument1Type === RuleArgumentType.Value) {
        this.updateControlValidationsByType(
          validationsSet.form.ruleArgument1Value,
          this.selectedAppliesTo.type
        );
      }

      if (this.form.ruleArgument2Type === RuleArgumentType.Value) {
        this.updateControlValidationsByType(
          validationsSet.form.ruleArgument2Value,
          this.selectedAppliesTo.type
        );
      }
    }

    return validationsSet;
  }

  public updateControlValidationsByType(
    formControl: any,
    propertyType: string
  ): void {
    if (propertyType.includes('Int32')) {
      formControl.integer = integer;
    } else if (
      propertyType.includes('Decimal') ||
      propertyType.includes('Single')
    ) {
      formControl.decimal = decimal;
    }
  }

  public resetControlsValidationsTypeRules(formControls: any[]): void {
    formControls.forEach((formControl: any) => {
      formControl.integer = new Object();
      formControl.decimal = new Object();
    });
  }

  public created(): void {
    this.loadTemplateRules();
    this.loadDataSources();
  }

  public getUUId(): string {
    return `${_.uniqueId()}`;
  }

  @Emit('close')
  public close(): boolean {
    return this.rulesChanged;
  }

  public onConditionOperationChange(
    operation: ConditionOperation | null
  ): void {
    this.form.conditionOperation = operation;

    if (this.form.conditionOperation === ConditionOperation.In) {
      this.form.conditionRightOperandType = RuleArgumentType.Value;
      this.form.conditionRightOperand = null;
    }
  }

  public onArgumentsOperationChange(operation: RuleOperation | null): void {
    this.form.ruleOperation = operation;

    switch (operation) {
      case RuleOperation.SetTo: {
        this.form.ruleArgument2Type = RuleArgumentType.Field;
        this.form.ruleArgument2Value = null;
        break;
      }
      case RuleOperation.Coalesce: {
        if (this.form.ruleArgument1Type !== RuleArgumentType.Field) {
          this.form.ruleArgument1Type = RuleArgumentType.Field;
          this.form.ruleArgument1Value = null;
        }
        break;
      }
      case RuleOperation.Add:
      case RuleOperation.Subtract: {
        if (this.selectedAppliesTo?.type.includes('Date')) {
          this.dateOperationArgument2Value = 0;
          this.dateOperationMeasureUnit = 'days';
        }
        break;
      }
    }
  }

  public onDateOperationMeasureUnitChange(measureUnit: string): void {
    this.dateOperationMeasureUnit = measureUnit;
    this.form.ruleArgument2Value = `${this.dateOperationArgument2Value} ${this.dateOperationMeasureUnit}`;
  }

  public onDateOperationArgument2ValueChange(value: any): void {
    this.dateOperationArgument2Value = value === '' ? 0 : value;
    this.form.ruleArgument2Value = `${this.dateOperationArgument2Value} ${this.dateOperationMeasureUnit}`;
  }

  public onAppliesToChange(appliesTo: string): void {
    this.selectedAppliesTo = _.find(
      this.argumentOptions,
      (option) => option.value === appliesTo
    );

    this.form.appliesTo = appliesTo;
    this.form.ruleArgument1Value =
      this.form.ruleArgument1Value && this.form.ruleArgument1Value.toString();

    this.ruleOperations = importRuleOperations.filter((rc) =>
      rc.dataTypes.some((dt) => this.selectedAppliesTo?.type.includes(dt))
    );

    this.conditionLeftArguments =
      this.argumentOptions?.filter(
        (a) => a.type === this.selectedAppliesTo?.type
      ) ?? [];

    this.validations();
  }

  public onRuleArgument1TypeChange(value: string | null): void {
    this.form.ruleOperation =
      value === RuleArgumentType.Command
        ? RuleOperation.Execute
        : RuleOperation.SetTo;

    this.form.ruleArgument1Value = null;
  }

  public onConditionRightOperandTypeChange(): void {
    this.form.conditionRightOperand = null;
  }

  public add(): void {
    if (this.mappingOption?.dbColumn) {
      this.isEditMode = true;
      this.form = this.getEmptyForm();
      this.form.appliesTo = this.mappingOption?.dbColumn;
      this.form.conditionLeftOperand = this.mappingOption?.dbColumn;
      this.filteringParametersByTypes();
    }
  }

  public edit(importRule: IImportRule): void {
    this.isEditMode = true;
    this.form = {
      uuid: importRule.uuid,
      id: importRule.id,
      appliesTo: importRule.appliesTo,
      conditionLeftOperand: importRule.conditionLeftOperand,
      conditionOperation: importRule.conditionOperation,
      conditionRightOperandType: importRule.conditionRightOperandType?.toString(),
      conditionRightOperand: importRule.conditionRightOperand,
      ruleArgument1Type: importRule.ruleArgument1Type?.toString(),
      ruleArgument1Value: importRule.ruleArgument1Value,
      ruleOperation: importRule.ruleOperation,
      ruleArgument2Type: importRule.ruleArgument2Type?.toString(),
      ruleArgument2Value: importRule.ruleArgument2Value
    };

    this.filteringParametersByTypes();

    if (
      this.selectedAppliesTo &&
      this.selectedAppliesTo.type.includes('DateTime')
    ) {
      const dateValueToAddStringArray = this.form.ruleArgument2Value?.split(
        ' '
      );
      if (dateValueToAddStringArray) {
        this.dateOperationArgument2Value = Number.parseInt(
          dateValueToAddStringArray[0] ?? 0
        );
        this.dateOperationMeasureUnit =
          dateValueToAddStringArray[1]?.toLowerCase() ?? 'days';
      }
    }
  }

  public remove(importRuleId: number): void {
    ImportTemplateRuleService.remove(importRuleId).then(() => {
      this.loadTemplateRules();
      this.rulesChanged = true;
    });
  }

  public submit(): void {
    this.validate();

    if (this.isValid) {
      if (this.form.conditionOperation === ConditionOperation.NotMissing) {
        this.form.conditionRightOperand = null;
      }

      const promise = this.form.id
        ? ImportTemplateRuleService.update(this.form.id, this.form)
        : ImportTemplateRuleService.create(this.form);

      promise
        .then(() => {
          this.loadTemplateRules();
          this.rulesChanged = true;
        })
        .finally(() => {
          this.reset();
          this.isEditMode = false;
          this.ruleConditions = importRuleConditionOperations;
          this.ruleOperations = importRuleOperations;
        });
    }
  }

  public cancel(): void {
    this.isEditMode = false;
    this.form = this.getEmptyForm();

    this.ruleConditions = importRuleConditionOperations;
    this.ruleOperations = importRuleOperations;
  }

  private filteringParametersByTypes(): void {
    this.selectedRightOperand = _.find(
      this.argumentOptions,
      (option) => option.value === this.form.conditionLeftOperand
    );

    this.ruleConditions = importRuleConditionOperations.filter((rc) =>
      rc.dataTypes.some((dt) => this.selectedRightOperand?.type.includes(dt))
    );

    this.conditionRightArguments =
      this.argumentOptions?.filter(
        (a) => a.type === this.selectedRightOperand?.type
      ) ?? [];

    this.onAppliesToChange(this.form.appliesTo);
  }

  private loadTemplateRules(): void {
    const state: FiltrationState = {
      showDeleted: false,
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'conditionLeftOperand',
            operator: 'eq',
            value: this.mappingOption.dbColumn
          }
        ]
      },
      take: maxItemsInDropDown
    };

    ImportTemplateRuleService.getTemplateRules(state).then(
      (response: PaginatedResult<IImportRule>) => {
        this.rules = response.entities;
        this.setRuleArgumentsFormats();
      }
    );
  }

  private setRuleArgumentsFormats(): void {
    const argumentOptionsWithDateType = this.argumentOptions?.filter(
      (a) =>
        this.rules.some(
          (r) => r.appliesTo === a.value || r.conditionLeftOperand === a.value
        ) && a.type.includes('Date')
    );

    if (argumentOptionsWithDateType) {
      this.rules.forEach((r: IImportRule) => {
        if (
          r.conditionRightOperand &&
          r.conditionOperation === ConditionOperation.In
        ) {
          r.conditionRightOperandFormatted = JSON.parse(
            r.conditionRightOperand
          ).join(', ');
        }

        if (
          argumentOptionsWithDateType.some(
            (a) => a.value === r.conditionLeftOperand
          )
        ) {
          r.conditionRightOperandFormatted = moment(
            r.conditionRightOperand
          ).format('MM-DD-YYYY');
        }

        if (argumentOptionsWithDateType.some((a) => a.value === r.appliesTo)) {
          r.ruleArgument1ValueFormatted = moment(r.ruleArgument1Value).format(
            'MM-DD-YYYY'
          );
        }
      });
    }
  }

  private loadDataSources(): void {
    const state: FiltrationState = {
      showDeleted: false,
      take: maxItemsInDropDown
    };

    DataSourceService.getDataSources(state).then(
      (response: PaginatedResult<IDataSource>) => {
        this.dataSourceOptions = response.entities.map((o: IDataSource) => {
          return {
            value: o.name,
            label: o.name
          };
        });
      }
    );
  }
}
