import { required } from 'vuelidate/lib/validators';

const isArray = (value: string): boolean => {
    return value.match(/^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/) != null;
}

const maxArrayValues = (value: string): boolean => {
    const values: any[] = value.split(',').map(v => Number.parseFloat(v))
    return !values.some(i => i.toString().match(/^[0-9]{1,10}([,.][0-9]{1,5})?$/) == null)
        && !values.some(i => i.toFixed(0) < 0 || i.toFixed(0) > 1000000000);
}

export const VectorImportParamValidations = {
    form: {
        value: {
            required,
            isArray,
            maxArrayValues
        }
    }
};