























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { IParamData } from "@/interfaces/runPricingModel/parameters/commonParam.interface";
import {
  IRunPricingColumn,
  RunPricingDataType,
} from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";
import { withPopper } from "@/plugins/popover";
import { operationTypes } from "@/components/run-pricing-model/parameters/constants/operationTypes.const";
import { IRunPricingRuleSettings } from "@/interfaces/runPricingModel/runPricingRuleSettings.interface";
import {
  isInt,
  isFloat,
  isBool,
  isString,
  isRunPricingParameterValid,
} from "@/constants/validations/run-pricing-model-parameters.validation";
import moment from "moment";
import { OperationOperator } from "./enums/OperationOperator";

@Component({
  data: () => {
    return {
      RunPricingDataType,
      operationTypes,
    };
  },
  methods: {
    isInt,
    isFloat,
    isBool,
    isString,
  },
})
export default class CommonParam extends Vue {
  // properties
  @Prop({ required: true }) column: IRunPricingColumn;

  @Prop({
    required: false,
    default: () => {
      return { isOperationsEnable: false };
    },
  })
  settings: IRunPricingRuleSettings;

  @Prop({
    required: false,
    default: () => {
      return {
        value: null,
        inputValue: null,
        type: null,
        columnName: "",
        isOperation: false,
        operationType: null,
        operationValue: null,
      };
    },
  })
  data: IParamData;

  public isDirty: boolean = false;

  public popover = withPopper;
  public boolParamValues: string[] = ["True", "False"];

  public constructor() {
    super();
    if (this.data.isOperation) {
      this.data.operationType = this.data.operationType
        ? this.data.operationType
        : OperationOperator.Add;
    }
    if ((!this.data || !this.data.value) && this.column.defaultValue) {
      this.data.value = this.column.defaultValue;
      this.data.type = this.column.dataType;
    }
    if (!this.data.inputValue && this.data.value && !this.data.isOperation) {
      this.data.inputValue = this.data.value;
      this.data.type = this.column.dataType;
    }
    if (!this.data.inputValue && !this.data.isOperation && !this.data.value) {
      this.data.inputValue = this.column.defaultValue;
    }
  }

  public get isOperationSelected(): boolean {
    return (
      (this.data.operationValue &&
        this.data.operationType &&
        !this.data.isOperation) ??
      false
    );
  }

  public sendValue(type: RunPricingDataType): void {
    this.isDirty = true;
    this.data.type = type;
    this.data.columnName = this.column.columnName;
    this.data.columnType = this.column.type;
    if (this.column.dataType !== RunPricingDataType.DT) {
      this.data.isValid = isRunPricingParameterValid(this.data, []);
    }
    this.$emit("receiveParamValue", this.data);
  }

  public onUpdateOperations(value: boolean): void {
    if (this.data.isOperation) {
      this.data.operationType = this.data.operationType
        ? this.data.operationType
        : OperationOperator.Add;
    }
    if (value) {
      this.data.isOperationToggleOn = true;
      return;
    } else {
      this.data.isOperationToggleOn = false;
    }
    if (!this.data.inputValue) {
      this.data.inputValue = this.column.defaultValue;
    }
  }

  public isParameterToShow(): boolean {
    if (
      !this.settings.isOperationsEnable &&
      this.column.isOperation &&
      this.data.isOperation
    ) {
      return false;
    }

    return true;
  }
}
