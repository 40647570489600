<template>
  <ul v-if="stack.length > 0" class="crumbs">
    <li v-for="item in stack" v-bind:key="item.name" :class="item.class">
      <router-link
        :to="{ name: item.name }"
        class="crumbs__link"
      >
        {{ item.label }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BreadCrumbs",
  data() {
    return { stack: [] };
  },
  mounted() {
    this.buildStack();
  },
  methods: {
    buildStack() {
      this.stack = [];
      this.addPage(this.$router.currentRoute);
      this.stack = this.stack.splice(1);
      this.stack[0].label = this.stack[0].label.replace("/", "");
      this.stack = this.stack.reverse();
    },
    addPage(route) {
      this.stack.push({
        name: route?.name,
        label: route?.meta?.breadcrumb?.label || route?.name?.replace(".", " "),
      });
      if (route?.meta?.breadcrumb?.parent) {
        const parent = this.getRoute(route.meta.breadcrumb.parent);
        this.addPage(parent);
      }
    },
    getRoute(name) {
      return this.$router.options.routes[0].children.find(
        (route) => route?.name === name
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.crumbs {
  color: #dc1170;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  padding-left: 1%;
  flex-wrap: wrap;
}

.crumbs__link {
  color: #dc1170;
  font-size: 1rem;
  text-decoration: none;
}

li:after {
  content: "/";
  padding: 0 5px;
  font-size: 1rem;
}

li:last-child:after {
  content: "";
}
</style>