export enum ConditionOperation {
    Equals,
    LessThan,
    LessThanOrEquals,
    GreaterThan,
    GreaterThanOrEquals,
    Missing,
    Like,
    In,
    NotMissing
}