























































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { RunPricingModelValidations } from "@/constants/validations/run-pricing-model.validation";
import {
  IExecution,
  IRunPricingModel,
} from "@/interfaces/runPricingModel/runPricingModel.interface";
import { ILoanPool } from "@/interfaces/runPricingModel/loanPool.interface";
import { IRevision } from "@/interfaces/runPricingModel/revision.interface";
import { IScenario } from "@/interfaces/runPricingModel/scenario.interface";
import { withPopper } from "@/plugins/popover";
import { FiltrationState } from "@/models/filtrationState";
import { maxItemsInDropDown } from "@/constants/stateConstants";
import DealService from "@/services/deal.service";
import ScenarioService from "@/services/scenario.service";
import { PaginatedResult } from "@/models/paginatedResult";
import RunPricingModelModule from "@/store/modules/runPricingModel.module";
import { getModule } from "vuex-module-decorators";
import { RunPricingModelState } from "@/models/runPricingModelState";
import { IScenarioDetails } from "@/interfaces/runPricingModel/scenarioDetails.interface";
import RunPricingService from "@/services/run-pricing.service";
import AzureDataService from "@/services/azure-data.service";
import { checkIfValid } from "@/utils/validators";
import { IGlobalRule } from "@/interfaces/runPricingModel/globalRule.interface";
import { ILocalRule } from "@/interfaces/runPricingModel/localRule.interface";
import PermissionsModule from "@/store/modules/permissions.module";
import { ApiResponseType } from "@/enums/api-response-type";
import RulesList from "./RulesList.vue";
import { validPromise } from "@/utils/validPromise";
import { RPMPagesType } from "@/enums/rpm-pages-type";

@Component({
  mixins: [validationMixin],
  validations: RunPricingModelValidations,
  components: {
    "rules-list": RulesList,
  },
  methods: {
    updateName: function (this: any, e: any) {
      this.scenarioForm.name = e;
      this.$v.scenarioForm.$touch();
    },
  },
  data() {
    return {
      isRouteLeaving: false,
      isFormChanged: false,
      nextRoute: Function,
      isGoingForward: false,
    };
  },
  async beforeRouteLeave(to, from, next) {
    await this.formChanged();
    this.$data.isRouteLeaving = this.isDirty;

    if (this.$data.isGoingForward) {
      this.$data.isRouteLeaving = false;
    }

    if (!this.$data.isRouteLeaving) {
      next();
    }

    this.$data.isGoingForward = false;
    this.$data.nextRoute = next;
  },
})
export default class RunPricingModel extends Vue {
  public get loanPoolOptions(): any[] {
    return [
      ...this.loanPool.map((it: ILoanPool) => {
        return {
          value: it.id,
          label: it.name,
        };
      }),
    ];
  }

  public get revisionOptions(): any[] {
    return [
      ...this.revisions.map((it: IRevision) => {
        return {
          value: it.id,
          label: it.name,
        };
      }),
    ];
  }

  public get modelVersionOptions(): any[] {
    return [...this.modelVersions];
  }

  public get scenarioOptions(): any[] {
    return [
      ...this.scenarios.map((it: IScenario) => {
        return {
          value: it.id,
          label: it.name,
        };
      }),
    ];
  }

  // refs
  public form = this.getEmptyForm();
  public pristineForm = this.getEmptyForm();
  public loanPool: ILoanPool[] = [];
  public revisions: IRevision[] = [];
  public modelVersions: string[] = [];
  public scenarios: IScenario[] = [];
  public popover = withPopper;
  public search: string | null = null;
  public selectedScenario: IScenarioDetails | null = null;
  public originalScenarioName: string | null = null;
  public numberOfItemsLoaded: number = 0;
  public isLoadMore: boolean;
  public ruleModalText: string = "";
  public ruleModalShow: boolean = false;
  public deleteScenarioShow: boolean = false;
  private runPricingModelModule: RunPricingModelModule;
  private permissionsModule: PermissionsModule;
  //Scenario properties
  public scenarioForm = this.getEmptyScenarioForm();
  public scenarioPristineForm = this.getEmptyScenarioForm();
  public isCreateOrUpdateScenario: boolean = false;
  public isCreateModeActive: boolean = false;
  public prevAction: string = "";
  public isAddScenarioActive: boolean = true;
  public dsUserName = "dsUser";
  public isSaveExecution: boolean = false;
  public isRunPricingModel: boolean = false;
  public isSaveAfterSubmit: boolean = false;
  public isScenarioChanged: boolean = false;
  public submitPressed: boolean = false;
  public isAddButtonPressed: boolean = false;
  public selectedGlobalRule: IGlobalRule | null;
  public selectedLocalRule: ILocalRule | null;
  public isDirty: boolean = false;

  private selectedExecutionId: number | null = null;
  private existingExecutionCollapse: boolean = false;

  public constructor() {
    super();
    this.runPricingModelModule = getModule(RunPricingModelModule);
    this.permissionsModule = getModule(PermissionsModule);
  }

  protected getEmptyForm(): IRunPricingModel {
    return {
      dealId: null,
      revisionId: null,
      description: "",
      scenarioId: null,
      modelImageTag: null,
      runPricing: false,
    };
  }

  protected getEmptyScenarioForm(): IScenarioDetails {
    return {
      name: "",
      id: null,
      localRules: [],
      globalRules: [],
    };
  }

  @Watch("scenarioPristineForm")
  public pristineFormChanged() {
    if (this.scenarioPristineForm !== this.getEmptyScenarioForm()) {
      this.$data.isFormChanged = true;
    }
  }

  @Watch("scenarios")
  public scenariosChanged() {
    if (this.selectedScenario && !this.selectedScenario?.id) {
      this.selectedScenario.id = this.scenarios.find(
        (x) => x.name === this.selectedScenario?.name
      )?.id;
      this.form.scenarioId = this.scenarios.find(
        (x) => x.name === this.selectedScenario?.name
      )?.id;
    }
  }

  public created(): void {
    this.searchDeals(null, false, 0, false);
    this.searchScenarios(null, false, 0, false);
    this.loadModelVersions();
    this.loadRunPricingModelState();
  }

  public isControlValid(fieldName: string): boolean {
    return checkIfValid(this.$v.scenarioForm, fieldName);
  }

  public loadScenario(id: number): void {
    ScenarioService.getById(id).then((value) => {
      this.scenarioPristineForm = value;
      this.scenarioForm = value;
    });
  }

  public checkUserRole(roleToCheck: string): boolean {
    const userRoles = this.permissionsModule.getCurrentUserRoles;
    if (userRoles) {
      return userRoles.roles.includes(roleToCheck);
    }
    return false;
  }

  public getModelPlaceholder(): string | null {
    return this.form.modelImageTag ?? "Model version";
  }

  public cancelEditing(): void {
    if (this.isAddScenarioActive) {
      this.scenarioForm = this.scenarioPristineForm;
    }

    if (this.selectedScenario) {
      this.selectedScenario.name = <string>(
        this.scenarios.find((x) => x.id === this.form.scenarioId)?.name
      );
    }

    this.isCreateOrUpdateScenario = false;
    this.isCreateModeActive = false;
  }

  public async submit(): Promise<boolean> {
    this.validateScenarioForm();
    let valid: boolean = true;

    if (this.$v.scenarioForm.$invalid) {
      await validPromise.call(this, "scenarioForm");

      if (!valid) {
        this.hideConfirmationPopups();
        return valid;
      }
    }

    if (!this.$v.scenarioForm.$invalid) {
      this.scenarioForm.localRules.forEach(
        (lr: ILocalRule, index: number) => (lr.sequence = index + 1)
      );

      this.scenarioForm.globalRules.forEach(
        (gr: IGlobalRule, index: number) => (gr.sequence = index + 1)
      );

      if (this.scenarioForm.id || this.form.scenarioId) {
        await this.updateScenario();
      } else {
        await this.createScenario();
      }

      this.isCreateOrUpdateScenario = false;
      this.isAddScenarioActive = true;
      this.scenarioForm.id = this.form.scenarioId;
      this.selectedScenario = this.scenarioForm;
      this.scenarioPristineForm = JSON.parse(JSON.stringify(this.scenarioForm));
    }

    return valid;
  }

  @Watch("form", { deep: true })
  public async formChanged() {
    this.isDirty =
      await this.runPricingModelModule.checkFormIsDirty({ 
        form: this.$data.scenarioForm,
        pristineForm: this.$data.scenarioPristineForm,
        type: RPMPagesType.RunPricing
      });
  }

  private hideConfirmationPopups(): void {
    this.$data.isRouteLeaving = false;
    this.isSaveExecution = false;
    this.isRunPricingModel = false;
    this.isScenarioChanged = false;
    this.isAddButtonPressed = false;
  }

  public setEmptyForms(): void {
    this.form = this.getEmptyForm();
    this.pristineForm = this.getEmptyForm();
    this.scenarioForm = this.getEmptyScenarioForm();
    this.scenarioPristineForm = this.getEmptyScenarioForm();
  }

  public resetButtonFlags(): void {
    this.isCreateOrUpdateScenario = false;
    this.isCreateModeActive = false;
    this.isAddScenarioActive = true;
    this.selectedScenario = null;
  }

  private async updateScenario(): Promise<void> {
    if (this.scenarioForm.id) {
      return ScenarioService.update(this.scenarioForm.id, this.scenarioForm)
        .then(() => {
          this.searchScenarios(null, false, 0, false);
          Vue.$toast.clear();
          Vue.$toast.success(`Scenario ${this.scenarioForm.name} updated`);
        })
        .catch((error) => {
          Vue.$toast.clear();
          Vue.$toast.error(error);
        });
    }
  }

  public isScenarioFormDirty(): boolean {
    return (
      JSON.stringify(this.$data.scenarioForm) !==
        JSON.stringify(this.$data.scenarioPristineForm) &&
      this.$data.isFormChanged
    );
  }

  public async scenarioChanged(): Promise<void> {
    const isFormDirtyAndValid = this.isScenarioFormDirty();
    if (!this.form.scenarioId) {
      this.isCreateModeActive = false;
    }

    if (!isFormDirtyAndValid) {
      await this.getScenarioInfo();
      await validPromise.call(this, "scenarioForm");
      this.saveState(true);
      return;
    }

    this.isScenarioChanged = true;
  }

  public addButtonPressed(): void {
    const isFormDirtyAndValid = this.isScenarioFormDirty();

    if (!isFormDirtyAndValid) {
      this.createOrEditScenario("add");
      return;
    }

    this.isAddButtonPressed = true;
  }

  public saveExecutionPressed(): void {
    this.validate();
    const isFormDirtyAndValid =
      (this.isScenarioFormDirty() ||
        (!this.form.scenarioId && this.scenarioForm.name.length > 0)) &&
      !this.$v.form.$invalid;

    if (!isFormDirtyAndValid) {
      this.runPricingModel(false);
      return;
    }

    this.isSaveExecution = true;
  }

  public runPricingModelPressed(): void {
    this.validate();
    const isFormDirtyAndValid =
      (this.isScenarioFormDirty() ||
        (!this.form.scenarioId && this.scenarioForm.name.length > 0)) &&
      !this.$v.form.$invalid;

    if (!isFormDirtyAndValid) {
      this.runPricingModel(true);
      return;
    }

    this.isRunPricingModel = true;
  }

  private createScenario(): Promise<void> {
    return ScenarioService.create(this.scenarioForm)
      .then((scenarioId: number) => {
        this.searchScenarios(null, false, 0, false);
        this.form.scenarioId = scenarioId;
        Vue.$toast.clear();
        Vue.$toast.success(`Scenario ${this.scenarioForm.name} created`);
      })
      .catch((error) => {
        Vue.$toast.clear();
        switch (error.request.status) {
          case 409:
            Vue.$toast.error("Scenario already exists");
            break;
          default:
            Vue.$toast.error(error);
        }
      });
  }

  public validate(): void {
    this.$v.$touch();
    this.$v.form.$touch();
  }

  public validateScenarioForm(): void {
    this.$v.form.scenarioId?.$touch();
    this.$v.scenarioForm.$touch();
  }

  private waitForSubmit() {
    return new Promise<void>((resolve) => {
      const unwatch = this.$watch(
        () => this.submitPressed,
        (isValid) => {
          if (!isValid) {
            unwatch();
            resolve();
          }
        },
        { immediate: true }
      );
    });
  }

  public searchDeals(
    search: string | null,
    loading: boolean,
    skipCount: number,
    isLoadMore: boolean
  ): void {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search !== this.search) {
      this.numberOfItemsLoaded = 0;
    }

    this.search = search;

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }

    this.loadDeals(state, isLoadMore);
  }

  public async returnToHome({
    item = "Home",
    save = true,
  } = {}): Promise<void> {
    if (save) {
      const isValid = await this.submit();
      this.saveState();

      if (!isValid) {
        return;
      }
    } else {
      this.scenarioForm.name = <string>(
        this.scenarios.find((x) => x.id === this.form.scenarioId)?.name
      );
    }

    if (this.$data.nextRoute) {
      this.$data.nextRoute();
      return;
    }

    this.$router.push({
      name: item,
    });
  }

  public searchScenarios(
    search: string | null,
    loading: boolean,
    skipCount: number,
    isLoadMore: boolean
  ): void {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search !== this.search) {
      this.numberOfItemsLoaded = 0;
    }

    this.search = search;

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }

    this.loadScenarios(state, isLoadMore);
  }

  public loanPoolChanged(id: number): void {
    const loanPool = this.loanPool.find((value) => {
      return value.id === id;
    });
    if (loanPool) {
      this.revisions = loanPool.revisions.sort((a, b) => b.id - a.id);
      this.form.revisionId = loanPool.revisions[0].id;
    } else {
      this.form.revisionId = null;
    }
  }

  public removeScenario(id: number): void {
    ScenarioService.remove(id)
      .then(() => {
        this.$toast.success("Scenario was successfully deleted");
        this.scenarios = this.scenarios.filter((value) => value.id !== id);
        this.scenarioForm = this.getEmptyScenarioForm();
        this.scenarioPristineForm = this.getEmptyScenarioForm();
        this.form.scenarioId = null;
        this.runPricingModelModule.updateState(new RunPricingModelState());
        this.$v.form.scenarioId?.$reset();
        this.selectedScenario = null;
        this.isCreateOrUpdateScenario = false;
        this.isCreateModeActive = false;
      })
      .catch((error) => {
        this.$toast.error(error.message);
      });
  }

  private async saveFormAfterSubmit(): Promise<void> {
    if (this.isSaveAfterSubmit) {
      this.submitPressed = true;
      await this.submit().then(() => {
        this.isSaveAfterSubmit = false;
        this.submitPressed = false;
      });
      this.waitForSubmit();
    }
  }

  public async createOrEditScenario(action: string): Promise<void> {
    this.originalScenarioName = this.scenarioForm.name;
    if (action === "update") {
      this.validateScenarioForm();
      this.scenarioForm = <IScenarioDetails>this.selectedScenario;
      this.isAddScenarioActive = false;
    } else {
      await this.saveFormAfterSubmit();
      this.scenarioForm = this.getEmptyScenarioForm();
      this.scenarioPristineForm = this.getEmptyScenarioForm();
      this.selectedScenario = null;
      this.form.scenarioId = null;
      this.isAddScenarioActive = true;
    }

    if (!this.prevAction || this.prevAction === action) {
      this.isCreateOrUpdateScenario = !this.isCreateOrUpdateScenario;
      this.isCreateModeActive = !this.isCreateModeActive;
    } else {
      this.isCreateOrUpdateScenario = true;
      this.isCreateModeActive = true;
    }

    this.prevAction = action;
  }

  public saveState(saveScenario: boolean = false): void {
    let state = this.runPricingModelModule.runPricingModelState;
    if (!state) {
      state = new RunPricingModelState();
    }

    if (!saveScenario) {
      state.runPricingModel = this.form;
      state.isCreateOrUpdateScenario = this.isCreateOrUpdateScenario;
      state.isCreateModeActive = this.isCreateModeActive;
    }

    state.selectedScenario = JSON.parse(JSON.stringify(this.scenarioForm));
    this.runPricingModelModule.updateState(state);
  }

  public async getScenarioInfo(): Promise<void> {
    await this.saveFormAfterSubmit();
    if (this.form.scenarioId) {
      ScenarioService.getById(this.form.scenarioId).then((scenario) => {
        this.selectedScenario = scenario;
        this.scenarioForm = scenario;

        this.scenarioPristineForm = JSON.parse(JSON.stringify(scenario));
      });
    } else {
      this.scenarioForm = this.getEmptyScenarioForm();
      this.selectedScenario = null;
      this.scenarioPristineForm = this.getEmptyScenarioForm();
    }
  }

  public async runPricingModel(runPricing: Boolean): Promise<void> {
    this.validate();

    if (!this.$v.form.$invalid) {
      await this.saveFormAfterSubmit();
      RunPricingService.runPricingModel(this.form, runPricing).then(() => {
        this.reset();
        Vue.$toast.clear();
        if (runPricing) {
          Vue.$toast.success(`Pricing model executed`);
        } else {
          Vue.$toast.success(`Pricing model execution saved`);
        }
      });
    }
  }

  public deleteExecution(executionId: number): void {
    RunPricingService.deleteExecution(executionId)
      .then((result) => {
        this.selectedExecutionId = null;
        Vue.$toast.clear();
        if (result.type === ApiResponseType.Success) {
          Vue.$toast.success(result.message);
        } else {
          Vue.$toast.error(result.message);
        }
      })
      .catch((error: any) => {
        Vue.$toast.error(error.message);
      });
  }

  public runPropensityModel(): void {
    Vue.$toast.clear();
    Vue.$toast.info("This functionality is in development");
  }

  public runExistingExecution(): void {
    if (!this.selectedExecutionId) {
      return;
    }
    RunPricingService.runExistingExecution(this.selectedExecutionId)
      .then(() => {
        Vue.$toast.clear();
        Vue.$toast.success(`Pricing model executed`);
      })
      .catch((error: any) => {
        Vue.$toast.error(error.message);
      });
  }

  public openExecutionModal(): void {
    RunPricingService.getExecution(this.selectedExecutionId ?? 0).then(
      (value: IExecution) => {
        let text = `${value.globalRules}${value.localRules}`;
        this.ruleModalText = text;
      }
    );

    this.ruleModalShow = true;
  }

  public closeRuleModal(): void {
    this.ruleModalShow = false;
  }

  private reset(): void {
    this.form = this.getEmptyForm();
    this.$v.form.$reset();
    this.selectedScenario = null;
    this.scenarioForm = this.getEmptyScenarioForm();
    this.scenarioPristineForm = this.getEmptyScenarioForm();
    const state: RunPricingModelState = new RunPricingModelState();

    state.runPricingModel = this.form;
    state.selectedScenario = null;
    this.runPricingModelModule.updateState(state);
  }

  private async loadRunPricingModelState(): Promise<void> {
    if (this.runPricingModelModule.runPricingModelState) {
      this.form =
        this.runPricingModelModule.runPricingModelState.runPricingModel ??
        this.getEmptyForm();
      this.form.scenarioId = this.runPricingModelModule.runPricingModelState
        .selectedScenario
        ? this.runPricingModelModule.runPricingModelState.selectedScenario.id
        : 0;
      this.pristineForm = this.runPricingModelModule.runPricingModelState
        .runPricingModel
        ? JSON.parse(
            JSON.stringify(
              this.runPricingModelModule.runPricingModelState.runPricingModel
            )
          )
        : this.getEmptyForm();
      this.isCreateOrUpdateScenario =
        this.runPricingModelModule.runPricingModelState.isCreateOrUpdateScenario;
      this.isCreateModeActive =
        this.runPricingModelModule.runPricingModelState.isCreateModeActive;
      this.selectedGlobalRule =
        this.runPricingModelModule.runPricingModelState.selectedGlobalRule;
      this.selectedLocalRule =
        this.runPricingModelModule.runPricingModelState.selectedLocalRule;
      this.pristineForm.scenarioId = this.runPricingModelModule
        .runPricingModelState.selectedScenario
        ? this.runPricingModelModule.runPricingModelState.selectedScenario.id
        : 0;

      if (this.runPricingModelModule.runPricingModelState.selectedScenario) {
        this.scenarioForm =
          this.runPricingModelModule.runPricingModelState.selectedScenario;
        this.selectedScenario = this.scenarioForm;
        this.scenarioPristineForm = JSON.parse(
          JSON.stringify(this.scenarioForm)
        );
      }
      await validPromise.call(this, "scenarioForm");
    }
  }

  private loadDeals(state: FiltrationState, isLoadMore: boolean): void {
    DealService.getDealsForLoanPool(state).then(
      (resp: PaginatedResult<ILoanPool>) => {
        if (isLoadMore) {
          for (const deal of resp.entities) {
            this.loanPool.push(deal);
          }
        } else {
          this.loanPool = resp.entities;
        }

        this.numberOfItemsLoaded += resp.entities.length;
        if (resp.totalCount > this.numberOfItemsLoaded) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
          this.numberOfItemsLoaded = 0;
        }
        if (this.form.dealId) {
          const loanPool = this.loanPool?.find((value) => {
            return value.id === this.form.dealId;
          });
          if (loanPool) {
            this.revisions = loanPool.revisions.sort((a, b) => b.id - a.id);
          }
        }
      }
    );
  }

  private loadScenarios(state: FiltrationState, isLoadMore: boolean): void {
    ScenarioService.getScenarios(state).then(
      (resp: PaginatedResult<IScenario>) => {
        if (isLoadMore) {
          for (const scenario of resp.entities) {
            this.scenarios.push(scenario);
          }
        } else {
          this.scenarios = resp.entities;
        }

        this.numberOfItemsLoaded += resp.entities.length;
        if (resp.totalCount > this.numberOfItemsLoaded) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
          this.numberOfItemsLoaded = 0;
        }
      }
    );
  }

  private loadModelVersions(isForceRefresh: boolean = false): void {
    AzureDataService.getModelVersions(isForceRefresh)
      .then((resp: string[]) => {
        this.modelVersions = resp;
        if (!this.checkUserRole(this.dsUserName)) {
          this.form.modelImageTag = resp[resp.length - 1];
        }
      })
      .catch((error) => {
        this.modelVersions = [];
        this.$toast.error(error.message);
      });
  }

  public changingRule(): void {
    this.saveState();
    this.$data.isGoingForward = true;
  }

  public receiveGlobalRules(rules: IGlobalRule[]): void {
    this.scenarioForm.globalRules = rules;
  }

  public receiveLocalRules(rules: ILocalRule[]): void {
    this.scenarioForm.localRules = rules;
  }
}
