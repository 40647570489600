import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import SubsetRuleService from '@/services/subset-rule.service';

const isUnique = async (value: string | null, currentId?: number) => {
    if (!value || value === '') {
        return true;
    } else {
        const resp = await SubsetRuleService.getExistSubsetRuleOrDefault(value, currentId);
        return (!resp.id || resp.id < 1) && value !== 'Select All'
    }
}

const isCorrectTreeLogic = (value: any): boolean => {
    return checkIfEntityAndChildrenAreValid(value);
}

const checkIfEntityAndChildrenAreValid = (entity: any): boolean => {
    let response = true;
    if (entity.rules) {
        entity.rules.forEach((rule: any) => {
            if (!checkIfEntityAndChildrenAreValid(rule)) {
                response = false;
            }
        });
    } else {
        if (entity.field === null || entity.field.toString().length === 0) {
            response = false;
        }

        if (entity.operator === null || entity.operator.toString().length === 0) {
            response = false;
        }
    }

    return response;
}

export const SubsetRuleValidations = {
    form: {
        id: {},
        name: {
            required,
            maxLength: maxLength(55),
            unique: (value: string, vm: any) => {
                return isUnique(value, vm.id)
            }
        },
        rule: requiredIf((form) => {
            return form.rule != null && isCorrectTreeLogic(form.rule);
        })
    }
};