import LoaderModule from '@/store/modules/loader.module';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { getModule } from 'vuex-module-decorators';
import { Vue } from 'vue-property-decorator';
import { HttpStatus } from '@/constants/httpStatuses.const';
import { AuthenticationContext } from 'vue-adal';
import PermissionsModule from '../../store/modules/permissions.module';

const loaderModule = getModule(LoaderModule);
const permissionsModule = getModule(PermissionsModule);

export const axiosInstance = Axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        useGlobalLoader: true,
        useToastNotification: true
    }
})

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        if (config.headers.useGlobalLoader) {
            loaderModule.startLoading();
        }

        if (AuthenticationContext.user) {
            const token = localStorage.getItem('adal.idtoken');
            config.headers.common.Authorization = `Bearer ${token}`;
            if(!permissionsModule.isPermissionsLoaded && !permissionsModule.isLoadingPermissions) {
                permissionsModule.getUserRoles();
            }
        } else {
            AuthenticationContext.login();
        }

        return config;
    },
    (error: any) => {
        loaderModule.finishLoading();
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        if (response.config.headers.useGlobalLoader) {
            loaderModule.finishLoading();
        }

        if (response.data.body != null && response.data.body.message) {
            return Promise.reject(response)
        }

        if (response.data.body != null
            && (response.data.body.data !== null || response.data.body.data !== undefined)
            && response.data.url.startsWith(response.config.baseURL)) {
            response.data = response.data.clone({
                body: response.data.body.data,
                status: response.data.status,
                headers: response.data.headers,
                statusText: response.data.statusText
            });
        }

        return Promise.resolve(response)
    },
    (error: AxiosError<any>) => {
        loaderModule.finishLoading();

        if (error.config.headers.useToastNotification) {
            let errorMessage = null;

            if (error.response) {
                switch (error.response.status) {
                    case HttpStatus.UNAUTHORIZED:
                        AuthenticationContext.login();
                        break;
                    case HttpStatus.CONNECTION_REFUSED:
                        errorMessage = `Unknown Error for url ${error.response.config.url}. Probably connection refused.`;
                        break;
                    default:
                        if (error.response.data) {
                            if (error.response.data.errors) {
                                let validationErrors: string[] = [];
                                Object.keys(error.response.data.errors).forEach(i => {
                                    validationErrors = validationErrors.concat(error.response?.data.errors[i]);
                                });

                                errorMessage = validationErrors.join(';');
                            } else {
                                errorMessage = error.response.data.detail ?? error.response.data.title ?? error.response.data.message;
                            }
                        }
                }
            } else {
                errorMessage = error.message;
            }

            if (errorMessage) {
                Vue.$toast.error(errorMessage);
            }
        }

        return Promise.reject(error)
    }
);