var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"json-query-group"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1 btn-group btn-group-sm"},[_c('span',{staticClass:"mr-2 subtext",class:{
          'inactive-text': _vm.currentQuery.condition === _vm.queryRuleCondition.or
        }},[_vm._v("AND")]),_c('c-switch',{staticClass:"primary-switch-slider m-auto",attrs:{"shape":"pill","checked":_vm.currentQuery.condition === _vm.queryRuleCondition.or},on:{"update:checked":_vm.onUpdateCondtion}}),_c('span',{staticClass:"ml-2 subtext",class:{
          'inactive-text': _vm.currentQuery.condition === _vm.queryRuleCondition.and
        }},[_vm._v("OR")])],1),_c('div',{staticClass:"ml-3 btn-group btn-group-sm"},[_c('c-button',{staticClass:"btn-primary custom-btn-rounded mr-2",on:{"click":_vm.addRule}},[_vm._v(" + Add Rule ")]),_c('c-button',{staticClass:"btn-primary custom-btn-rounded mr-2",on:{"click":_vm.addGroup}},[_vm._v(" + Add Ruleset ")]),(_vm.level > 0)?_c('c-button',{staticClass:"btn-primary custom-btn-rounded",on:{"click":_vm.deleteGroup}},[_vm._v(" - Remove Ruleset ")]):_vm._e()],1)]),_c('div',{staticClass:"json-query-rule-list"},[_vm._l((_vm.currentQuery.rules),function(item,index){return [_c('div',{key:index + '_level_' + _vm.level + '_container',staticClass:"json-query-rule-container",class:{
          'json-query-rule-only-one': _vm.currentQuery.rules.length < 2
        }},[(item.condition)?_c('div',{key:item._uuid,staticClass:"mt-2"},[_c('div',{staticClass:"p-2"},[_c('json-query-group',{key:item._uuid,ref:"groups",refInFor:true,attrs:{"currentQuery":item,"options":_vm.options,"level":_vm.level + 1},scopedSlots:_vm._u([{key:"field",fn:function(ref){
        var rule = ref.rule;
        var options = ref.options;
return [_vm._t("field",null,{"rule":rule,"options":options})]}},{key:"ruleOperator",fn:function(ref){
        var rule = ref.rule;
        var options = ref.options;
return [_vm._t("ruleOperator",null,{"rule":rule,"options":options})]}},{key:"number",fn:function(ref){
        var rule = ref.rule;
return [_vm._t("number",null,{"rule":rule})]}},{key:"date",fn:function(ref){
        var rule = ref.rule;
return [_vm._t("date",null,{"rule":rule})]}}],null,true)})],1)]):(_vm.options.length > 0)?_c('json-query-rule',{key:item._uuid,ref:"rules",refInFor:true,staticClass:"mt-2",attrs:{"rule":item,"options":_vm.options},scopedSlots:_vm._u([{key:"field",fn:function(ref){
        var rule = ref.rule;
        var options = ref.options;
return [_vm._t("field",null,{"rule":rule,"options":options})]}},{key:"ruleOperator",fn:function(ref){
        var rule = ref.rule;
        var options = ref.options;
return [_vm._t("ruleOperator",null,{"rule":rule,"options":options})]}},{key:"number",fn:function(ref){
        var rule = ref.rule;
return [_vm._t("number",null,{"rule":rule})]}},{key:"date",fn:function(ref){
        var rule = ref.rule;
return [_vm._t("date",null,{"rule":rule})]}}],null,true)}):_vm._e()],1)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }