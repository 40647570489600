var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-card-body',{staticClass:"card-body-container pt-3"},[_c('h5',{staticClass:"m-0 block-title"},[_vm._v(_vm._s(_vm.isLocal ? "Local" : "Global")+" Rules")]),_c('c-form',{staticClass:"form-container"},[_c('c-row',{staticClass:"d-flex align-content-center flex-wrap"},[_c('c-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('div',{staticClass:"scenario-block"},[_c('div',{staticClass:"select-rules"},[_c('v-select',{key:"rules",attrs:{"append-to-body":"","calculate-position":_vm.popover,"placeholder":("Select " + (_vm.isLocal ? 'Local' : 'Global') + " Rule"),"options":_vm.ruleOptions,"reduce":function (item) { return item; },"label":"name"},on:{"search":_vm.searchRules},model:{value:(_vm.selectedRule),callback:function ($$v) {_vm.selectedRule=$$v},expression:"selectedRule"}})],1),_c('div',[_c('c-button',{staticClass:"btn-primary",attrs:{"disabled":!_vm.selectedRule},on:{"click":_vm.addRuleToScenario}},[_vm._v(" + Add To Scenario ")]),_c('span',{staticClass:"divide-text"},[_vm._v("or")]),_c('c-button',{staticClass:"btn-primary mr-3",on:{"click":function($event){return _vm.createRule()}}},[_vm._v(" Create New Rule ")]),_c('c-button',{staticClass:"btn-primary copy-rule-btn",attrs:{"disabled":!_vm.selectedRule},on:{"click":function($event){return _vm.copyRule(_vm.selectedRule.id)}}},[_c('c-icon',{attrs:{"name":"cil-copy"}}),_vm._v(" Copy rule ")],1)],1)]),_c('div',{staticClass:"rules-list"},[_c('draggable',{staticClass:"list-group",attrs:{"tag":"ul","list":_vm.selectedRules,"handle":".handle"}},_vm._l((_vm.selectedRules),function(rule,index){return _c('c-list-group-item',{key:index,staticClass:"\n                    d-flex\n                    justify-content-left\n                    align-items-center\n                    pl-0\n                    pr-0\n                  ",class:{
                    'odd-row': index % 2 === 0,
                    'even-row': index % 2 !== 0,
                  }},[_c('i',{staticClass:"ml-1 cursor-pointer"},[_c('img',{staticClass:"align-self-center handle",attrs:{"src":"/icons/drag_indicator.svg"}})]),_c('div',{staticClass:"text-truncate primary-text"},[_vm._v(" "+_vm._s(rule.name)+" ")]),_c('div',{staticClass:"align-items-center secondary-text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-11 text-truncate description-block"},[_vm._v(" "+_vm._s(rule.description)+" ")])])]),_c('div',{staticClass:"btn-group"},[_c('c-button',{on:{"click":function($event){return _vm.openRuleModal(rule.id)}}},[_c('img',{staticClass:"glass-icon",attrs:{"src":"icons/magnifying-glass.svg"}})]),_c('c-button',{staticClass:"btn-outline-info custom-btn-rounded mr-2",on:{"click":function($event){return _vm.editRule(rule.id)}}},[_c('img',{attrs:{"src":"/icons/edit.svg"}}),_vm._v(" Edit ")]),_c('c-button',{staticClass:"btn-outline-danger custom-btn-rounded",on:{"click":function($event){_vm.deleteRule = true;
                        _vm.selectedRuleId = rule.id;}}},[_c('img',{attrs:{"src":"/icons/remove.svg"}})]),_c('div',{staticClass:"ml-2 tooltip-holder"},[(rule.description)?_c('img',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                          content: rule.description,
                          placement: 'left',
                          boundaries: 'viewport',
                        }),expression:"{\n                          content: rule.description,\n                          placement: 'left',\n                          boundaries: 'viewport',\n                        }"}],staticClass:"tooltip-img",style:([
                          rule.description
                            ? { visibility: 'visible' }
                            : { visibility: 'hidden' } ]),attrs:{"src":"/icons/tooltip.svg"}}):_vm._e()])],1)])}),1)],1)])])],1)],1)],1),(_vm.ruleModalShow)?_c('parameters-changes-modal',{attrs:{"text":_vm.ruleModalText},on:{"close":_vm.closeRuleModal}}):_vm._e(),(_vm.deleteRule)?_c('confirm-operation-modal',{attrs:{"operationProp":'delete',"confirmOperationDataToShowProp":[
      {
        label: 'Rule:',
        value: _vm.rules.find(function (x) { return x.id === _vm.selectedRuleId; }).name,
      } ]},on:{"submit":function($event){_vm.removeRuleFromScenario(_vm.selectedRuleId);
      _vm.deleteRule = false;},"cancel":function($event){_vm.deleteRule = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }