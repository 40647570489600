import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

@Module({
    name: 'loaderModule',
    namespaced: true,
    dynamic: true,
    store
})
export default class LoaderModule extends VuexModule {
    private _loading: number = 0;

    public get loading(): number {
        return this._loading;
    }

    @Mutation
    public startLoading(): void {
        this._loading++
    }

    @Mutation
    public finishLoading(): void {
        if (this._loading > 0) {
            this._loading--;
        }
    }
}