import { OperationOperator } from "@/components/run-pricing-model/parameters/enums/OperationOperator";

export interface IRunPricingModelConfig {
    categories: IRunPricingCategory[];
}

export interface IRunPricingCategory {
    name: string;
    explanation: string;
    columns: IRunPricingColumn[]
}

export interface IRunPricingColumn {
    columnName: string;
    displayName: string;
    isSubset: boolean;
    explanation: string;
    isUIDisabled: boolean | null;
    isLocal: boolean | null;
    isOperation: boolean | null;
    type: RunPricingColumnType;
    dataType: RunPricingDataType;
    pairedConstructorParam: IRunPricingColumn;
    pairedConstructorName: string;
    tableColumns: IRunPricingColumn[];
    constructors: IRunPricingConstructor[];
    defaultValue: any;
    defaultOperationTypeValue:OperationOperator;
}

export interface IRunPricingConstructor {
    name: string;
    parameters: IRunPricingColumn[];
}

export enum RunPricingColumnType {
    Column = 1,
    Param = 2,
    TableParam = 3,
    ConstructorParam = 4,
    PairedConstructorParam = 5
}

export enum RunPricingDataType {
    FLOAT,
    INT,
    STRING,
    BOOLEAN,
    DT,
    LIST_FLOAT,
    LIST_STRING,
    LIST_INT,
    LIST_BOOLEAN,
    TABLE
}