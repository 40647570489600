





















































































import { Component, Emit, Ref, Vue } from 'vue-property-decorator';
import { CDataTable } from '@coreui/vue';
import { IMapping } from '@/interfaces/importMapping/mapping.interface';
import { IFileInfoMapping } from '@/interfaces/importMapping/file-info-mapping.interface';
import { IColumnInfo } from '@/interfaces/tapeCrackers/column-info.interface';
import MappingTable from '@/components/import-mapping/MappingTable.vue';
import { getModule } from 'vuex-module-decorators';
import EntityDataModule from '@/store/modules/entity-data.module';
import { IImportMapping } from '@/interfaces/tapeCrackers/import-mapping.interface';
import { mapState } from 'vuex';
import TapeCrackerModule from '@/store/modules/tape-cracker.module';
import { IgnoreColumn } from '@/constants/stateConstants';
import { ILoanRawPropertyMetadata } from '@/interfaces/loanRaws/loan-raw-property-metadata.interface';

const tapeCrackerModule = getModule(TapeCrackerModule);
const entityDataModule = getModule(EntityDataModule);

@Component({
  components: { 'mapping-table': MappingTable }
})
export default class MappingEditor extends Vue {
  public filterDropDown = {
    label: 'Filter',
    filterButtonDisabled: false
  };

  @Ref() public readonly mappingTable: MappingTable;
  public formIsValid: boolean = false;

  public get mappingModel(): IMapping[] {
    const model = tapeCrackerModule.fileInfoMapping.fileInfo.columnsInfo.map(
      (columnInfo: IColumnInfo) => {
        const mappedItem = tapeCrackerModule.fileInfoMapping.mappings.find(
          (m: IMapping) => m.fileColumn === columnInfo.columnName
        );

        const propertyMetadata:
          | ILoanRawPropertyMetadata
          | undefined = mappedItem
          ? entityDataModule.loanRawPropertiesMetadata!.find(
              (m) => m.name === mappedItem.dbColumn
            )
          : undefined;

        const mapping: IMapping = {
          fileColumn: columnInfo.columnName,
          dbColumn:
            mappedItem?.dbColumn !== IgnoreColumn
              ? propertyMetadata?.name
              : IgnoreColumn,
          isDuplicate: mappedItem?.isDuplicate,
          dataSamples: columnInfo.dataSamples,
          hashing: mappedItem?.hashing,
          dataType: propertyMetadata?.type,
          nulls: propertyMetadata?.isRequired,
          confidenceLevel: mappedItem?.confidenceLevel,
          matchType: mappedItem?.matchType
        };

        return mapping;
      }
    );

    return model;
  }

  public get numberOfColumns(): number {
    return tapeCrackerModule.fileInfoMapping
      ? tapeCrackerModule.fileInfoMapping.fileInfo.columnsInfo.length
      : 0;
  }

  public get numberOfColumnsMatched(): number {
    return tapeCrackerModule.fileInfoMapping
      ? tapeCrackerModule.fileInfoMapping.mappings.filter(
          (m) => m.dbColumn && m.dbColumn !== IgnoreColumn
        ).length
      : 0;
  }

  public get numberOfLoans(): number {
    return tapeCrackerModule.fileInfoMapping
      ? tapeCrackerModule.fileInfoMapping.fileInfo.recordsCount
      : 0;
  }

  public constructor() {
    super();
  }

  @Emit('import')
  public import(): IImportMapping[] {
    return tapeCrackerModule.fileInfoMapping.mappings
      .filter((e) => e.dbColumn && e.dbColumn !== 'NaN')
      .map((m) => {
        return {
          fileColumn: m.fileColumn,
          dbColumn: m.dbColumn,
          sampleValues:
            m.dataSamples && m.dataSamples.length > 0 ? [m.dataSamples[0]] : [],
          mappedBy: m.matchType
        };
      });
  }

  public setAutoFilterState(): void {
    this.mappingTable.setAutofiltering();
    this.filterDropDown.label = 'Autofiltering on';
    this.filterDropDown.filterButtonDisabled = true;
  }

  public setManualFilterState(): void {
    this.mappingTable.setAutofiltering();
    this.filterDropDown.label = 'Filter';
    this.filterDropDown.filterButtonDisabled = false;
  }

  public onMappingModelUpdate(mappingModel: IMapping[]): void {
    tapeCrackerModule.fileInfoMapping.mappings = mappingModel;
  }

  public setFormState(state: boolean): void {
    this.formIsValid = state;
  }

  public submit(): void {
    this.import();
  }
}
