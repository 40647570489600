import { IScenarioDetails } from '@/interfaces/runPricingModel/scenarioDetails.interface';
import { IScenario } from '@/interfaces/runPricingModel/scenario.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import httpClient from '@/plugins/httpClient';
import { QueryBuilderService } from './query-builder.service';

class AzureDataService {
  public getModelVersions(isForceRefresh: boolean = false): Promise<string[]> {
    return httpClient.get<string[]>(`/azureDatas/GetTags?isForceRefresh=${isForceRefresh}` );
  }

 
}

export default new AzureDataService();