














































import { CHeader, CHeaderBrand } from '@coreui/vue';
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationContext } from 'vue-adal';

@Component({
  components: {
    'c-header': CHeader,
    'c-header-brand': CHeaderBrand
  }
})
export default class TheHeader extends Vue {
  public navbarDropdown: boolean = false;
  public isStandardizeAddressModaShow: boolean = false;

  public get isAuthenticated(): any {
    return AuthenticationContext?.user && localStorage.getItem('adal.idtoken');
  }

  public logout(): void {
    AuthenticationContext.login();
  }

  public login(): void {
    AuthenticationContext.logout();
  }
}
