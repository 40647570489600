export const subsetRuleYtmSpreadRows: any[] = [
    "Cur",
    "30",
    "60",
    "90",
    "120-179",
    "180+",
    "P BK",
    "NP BK",
    "FC",
    "REO"
]

export const subsetRuleYtmSpreadColumns: any[] = [
    "FHA",
    "VA",
    "USDA",
    "Uninsured"
]