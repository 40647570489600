import { IVectorSegmentParam } from '@/interfaces/runPricingModel/parameters/vectorSegmentParam.interface';

export function getVectorRange(segment: IVectorSegmentParam, limit: number = 0): number[] {
    if (segment && segment.start && segment.end && segment.vectorLength) {
        const startValue: number = +segment.start;
        const endValue: number = +segment.end;
        const vectorLength: number = +segment.vectorLength;
        const step: number = Math.abs((startValue - endValue)) / (vectorLength - 1);

        const range: number[] = [];
        if (step || step === 0 || vectorLength === 1) {
            let i: number = 0;
            do {
                if (i === 0) {
                    range.push(startValue);
                } else if (endValue > range[i - 1] + step + 1e-4 * step) {
                    const stepResult: string = (range[i - 1] + step).toFixed(5);
                    range.push(Number.parseFloat(stepResult));
                } else if (endValue <= range[i - 1] - (step < 0 ? -step : step) - 1e-4 * step) {
                    const stepResult: string = (range[i - 1] - (step < 0 ? -step : step)).toFixed(5);
                    range.push(Number.parseFloat(stepResult));
                } else {
                    range.push(endValue);
                }

                i++;
                if(limit > 0 && range.length >= limit)
                    break;
            } while (range.length < vectorLength);
        }

        return range;
    }

    return [];
}