var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-data-table',{attrs:{"items":_vm.form,"fields":_vm.fields,"clickableRows":false,"striped":"","addTableClasses":"mapping-table"},scopedSlots:_vm._u([{key:"Ignore-header",fn:function(){return [_c('div',{on:{"click":_vm.ignoreAllUnmapped}},[_vm._v("Ignore")])]},proxy:true},{key:"separator",fn:function(){return [_c('td',[_c('c-icon',{staticClass:"icon-style",attrs:{"name":"cil-arrow-right"}})],1)]},proxy:true},{key:"dbColumn",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-select',{key:_vm.getUUId(),class:{
            'is-invalid':
              _vm.isDuplicate(item) ||
              _vm.$v.form.$each.$iter[index].dbColumn.$invalid
          },attrs:{"calculate-position":_vm.popover,"append-to-body":"","lazy":false,"placeholder":"Please select","options":_vm.metadataOptions,"reduce":function (item) { return item.value; },"label":"label","value":_vm.$v.form.$each.$iter[index].dbColumn.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.form.$each.$iter[index].dbColumn, "$model", $event)},"input":function($event){return _vm.onColumnMap(item, $event, index)}}}),(!_vm.$v.form.$each.$iter[index].dbColumn.required)?_c('div',{staticClass:"validation-error-message"},[_vm._v(" This is a required field ")]):_vm._e(),(!_vm.$v.form.duplicates && _vm.isDuplicate(item))?_c('div',{staticClass:"validation-error-message"},[_vm._v(" This is a duplicate field ")]):_vm._e()],1)]}},{key:"Ignore",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('td',{staticClass:"remove-left-padding",on:{"click":function($event){$event.stopPropagation();}}},[(
            !_vm.$v.form.$each.$iter[index].dbColumn.$model ||
              _vm.isDuplicate(item) ||
              _vm.$v.form.$each.$iter[index].dbColumn.$model === 'Ignore'
          )?_c('span',{staticClass:"ignore-checkbox"},[_c('c-input-checkbox',{key:_vm.getUUId(),attrs:{"lazy":false,"checked":_vm.$v.form.$each.$iter[index].dbColumn.$model === 'Ignore'},on:{"click":function($event){return _vm.onIgnore(item, index, $event)}}})],1):_vm._e()])]}},{key:"hashing",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('td',[(item.dbColumn != null && item.dbColumn != 'Ignore')?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"hashing-rule-cell"},[_vm._v(" "+_vm._s(_vm.getRulesCount(_vm.$v.form.$each.$iter[index].dbColumn.$model))+" ")]),_c('div',{staticClass:"hashing-rule-cell",on:{"click":function($event){return _vm.openHashingRuleModal(_vm.$v.form.$each.$iter[index].$model)}}},[_c('c-icon',{staticClass:"icon-style",attrs:{"src":"/icons/outline-button.svg"}})],1)]):_vm._e()])]}},{key:"dataSample",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-truncate column-truncate"},[_vm._v(" "+_vm._s(item.dataSamples && item.dataSamples.length > 0 ? item.dataSamples[0] : '')+" ")])]}},{key:"dataType",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.dataType != null ? item.dataType : '')+" ")])]}},{key:"nulls",fn:function(ref){
          var item = ref.item;
return [_c('td',[(item.dbColumn == null)?_c('span'):(item.nulls === true)?_c('c-icon',{attrs:{"name":"cil-check-circle"}}):_c('c-icon',{attrs:{"name":"cil-x-circle"}})],1)]}},{key:"confidenceLevel",fn:function(ref){
          var item = ref.item;
return [_c('td',[(item.confidenceLevel != null)?_c('c-badge',{staticClass:"confidence-level",attrs:{"color":_vm.getBadge(item.confidenceLevel)}},[_vm._v(_vm._s(item.confidenceLevel))]):_vm._e()],1)]}},{key:"matchType",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.matchType != null ? item.matchType : '')+" ")])]}}])}),_c('span',{staticClass:"note-text"},[_vm._v("The following Db Columns are mandatory to be filled: "),_c('b',[_vm._v(_vm._s(_vm.mandatoryDbColumns.join(", ")))])]),(_vm.isHashingRuleModalShow)?_c('mapping-rule-modal',{attrs:{"mappingOption":_vm.currentMappingOption},on:{"close":_vm.closeHashingRuleModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }