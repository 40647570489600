import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import TheContainer from '@/components/layouts/TheContainer.vue'
import Home from '@/views/Home.vue';
import TapeCracker from '@/views/TapeCracker.vue';
import RunPricingModel from '@/components/run-pricing-model/RunPricingModel.vue';
import Scenario from '@/components/run-pricing-model/Scenario.vue';
import GlobalRule from '@/components/run-pricing-model/GlobalRule.vue';
import LocalRule from '@/components/run-pricing-model/LocalRule.vue';
import ParameterAdjustment from '@/components/run-pricing-model/ParameterAdjustment.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    component: TheContainer,
    children: [
      {
        name: 'Home',
        path: '',
        component: Home,
        meta: {
          requireAuth: true
        }
      },
      {
        name: 'TapeCracker',
        path: 'tapeCracker',
        component: TapeCracker
      },
      {
        name: 'RunPricingModel',
        path: 'runPricingModel',
        component: RunPricingModel,
        props: true,
        meta: {
          breadcrumb: {
            label: 'Run Pricing Model',
            parent: 'Home'
          }
        }
      },
      {
        name: 'GlobalRule',
        path: 'globalRule/:id?',
        component: GlobalRule,
        meta: {
          breadcrumb: {
            label: 'Global Rule',
            parent: 'RunPricingModel'
          }
        },
      },
      {
        name: 'LocalRule',
        path: 'localRule/:id?',
        component: LocalRule,
        meta: {
          breadcrumb: {
            label: 'Local Rule',
            parent: 'RunPricingModel'
          }
        },
      },
      {
        name: 'ParameterAdjustment',
        path: 'parameterAdjustment/:id?',
        component: ParameterAdjustment,
        meta: {
          breadcrumb: {
            label: 'Parameter Adjustment',
            parent: 'LocalRule'
          }
        },
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
