













































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  IRunPricingColumn,
  IRunPricingConstructor,
  RunPricingColumnType,
  RunPricingDataType,
} from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";
import { IParamData } from "@/interfaces/runPricingModel/parameters/commonParam.interface";
import _ from "lodash";
import { withPopper } from "@/plugins/popover";
import CommonParam from "./CommonParam.vue";
import { IRunPricingRuleSettings } from "@/interfaces/runPricingModel/runPricingRuleSettings.interface";

@Component({
  components: {
    "common-param": CommonParam,
  },
})
export default class ConstructorParam extends Vue {
  // properties
  @Prop({ required: true }) column: IRunPricingColumn;

  @Prop({
    required: false,
    default: () => {
      return { isOperationsEnable: false };
    },
  })
  settings: IRunPricingRuleSettings;

  @Prop({
    required: false,
    default: () => {
      return {
        value: [
          {
            name: "",
            parametersMaps: [],
            pair: {
              name: "",
              parametersMaps: [],
            },
          },
        ],
        type: RunPricingDataType.STRING,
        columnType: RunPricingColumnType.ConstructorParam,
        columnName: "",
        isValid: true,
      };
    },
  })
  data: IParamData;

  public popover = withPopper;

  public constructorParamCollapse: boolean = false;
  public constructorIndex: number = 0;

  @Emit("receiveParamValue")
  public receiveParamValue(value: IParamData): IParamData {
    return value;
  }

  public getConstructorParameters(name: string): IRunPricingColumn[] {
    const constructor: IRunPricingConstructor | undefined =
      this.column.constructors.find((c) => c.name === name);
    return constructor ? constructor.parameters : [];
  }

  public getPairedConstructorParameters(name: string): IRunPricingColumn[] {
    const constructor: IRunPricingConstructor | undefined =
      this.column.pairedConstructorParam.constructors.find(
        (c) => c.name === name
      );
    return constructor ? constructor.parameters : [];
  }

  public onSelectedConstructorChange($event: string, index: number): void {
    this.data.value[index].parametersMaps = [];

    if ($event) {
      this.data.value[index].name = $event;
      this.addParametersToConstructor(index);
    } else {
      this.data.value[index].name = "";
    }

    this.update();
  }

  public onSelectedPairedConstructorChange(
    $event: string,
    index: number
  ): void {
    this.data.value[index].pair.parametersMaps = [];

    if ($event) {
      this.data.value[index].pair.name = $event;
      this.addParametersToPairedConstructor(index);
    } else {
      this.data.value[index].pair.name = "";
    }
    this.update();
  }

  public addConstructor(): void {
    this.data.value.push({
      name: "",
      parametersMaps: [],
      pair: {
        name: "",
        parametersMaps: [],
      },
    });
    this.update();
  }

  public removeConstructor(index: number): void {
    if (this.data.value[index]) {
      this.data.value.splice(index, 1);
      this.update();
    }
  }

  public removeConstructorParametersMap(
    constructorIndex: number,
    parametersMapIndex: number
  ): void {
    if (
      this.data.value[constructorIndex] &&
      this.data.value[constructorIndex].parametersMaps[parametersMapIndex]
    ) {
      this.data.value[constructorIndex].parametersMaps.splice(
        parametersMapIndex,
        1
      );
      this.update();
    }
  }

  public addParametersToConstructor(constructorIndex: number): void {
    const parameterSettings: IRunPricingConstructor | undefined =
      this.column.constructors.find(
        (c) => c.name === this.data.value[constructorIndex].name
      );
    const parametersMap: { [key: string]: IParamData } = {};

    parameterSettings?.parameters.forEach((p: IRunPricingColumn) => {
      parametersMap[p.columnName] = {
        value: null,
        inputValue: null,
        type: p.dataType,
        columnName: p.columnName,
        pairName: p.pairedConstructorParam?.columnName,
        columnType: p.type,
        isOperation: false,
        isOperationToggleOn: false,
        isValid: true,
        operationType: null,
        operationValue: null,
      };
    });

    this.data.value[constructorIndex].parametersMaps.push(parametersMap);
    this.update();
  }

  public addParametersToPairedConstructor(constructorIndex: number): void {
    const parameterSettings: IRunPricingConstructor | undefined =
      this.column.pairedConstructorParam.constructors.find(
        (c) => c.name === this.data.value[constructorIndex].pair.name
      );
    const parametersMap: { [key: string]: IParamData } = {};

    parameterSettings?.parameters.forEach((p: IRunPricingColumn) => {
      parametersMap[p.columnName] = {
        value: null,
        inputValue: null,
        type: p.dataType,
        columnName: p.columnName,
        pairName: p.pairedConstructorParam?.columnName,
        columnType: p.type,
        isOperation: false,
        isValid: true,
        operationType: null,
        operationValue: null,
        isOperationToggleOn: false,
      };
    });

    this.data.value[constructorIndex].pair.parametersMaps.push(parametersMap);
    this.update();
  }

  public writeConstructorParamValue(
    $event: IParamData,
    constructorIndex: number,
    parametersMapIndex: number,
    columnName: string
  ): void {
    if (
      this.data.value[constructorIndex] &&
      this.data.value[constructorIndex].parametersMaps[parametersMapIndex] &&
      this.data.value[constructorIndex].parametersMaps[parametersMapIndex][
        columnName
      ]
    ) {
      this.data.value[constructorIndex].parametersMaps[parametersMapIndex][
        columnName
      ] = $event;

      this.update();
    }
  }

  public writePairConstructorParamValue(
    $event: IParamData,
    constructorIndex: number,
    parametersMapIndex: number,
    columnName: string
  ): void {
    if (
      this.data.value[constructorIndex] &&
      this.data.value[constructorIndex].pair &&
      this.data.value[constructorIndex].pair.parametersMaps[
        parametersMapIndex
      ] &&
      this.data.value[constructorIndex].pair.parametersMaps[parametersMapIndex][
        columnName
      ]
    ) {
      this.data.value[constructorIndex].pair.parametersMaps[parametersMapIndex][
        columnName
      ] = $event;

      this.update();
    }
  }

  public update(): void {
    this.data.columnName = this.column.columnName;
    this.data.pairName = this.column.pairedConstructorParam?.columnName;
    this.data.columnType = this.column.type;
    this.data.inputValue = this.data.value.filter((x: { name: any; }) => x.name);
    this.receiveParamValue(this.data);
  }

  public isConstructorValid(constructor: any) {
    let isValid = true;
    constructor.parametersMaps.forEach(
      (parameterMap: { [key: string]: IParamData }) => {
        Object.keys(parameterMap).forEach((key: string) => {
          if (!parameterMap[key].value && !parameterMap[key].inputValue) {
            isValid = false;
          }
        });
      }
    );
    return isValid;
  }

  public isConstructorsMatched(): boolean {
    if (!this.data.value || !this.column.pairedConstructorParam) {
      return true;
    }
    let isValid: boolean = true;
    this.data.value.forEach((constructor: any) => {
      if (
        (constructor.name && !constructor.pair.name) ||
        (!constructor.name && constructor.pair.name)
      ) {
        isValid = false;
      }
    });
    return isValid;
  }
}
