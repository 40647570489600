import { ICandidateAddress } from '@/interfaces/standardize-addresses/candidate-address.interface';
import { IStandardizeAddressCommand } from '@/interfaces/standardize-addresses/standardize-address-command.interface';
import httpClient from '@/plugins/httpClient';

class StandardizeAddressService {
    public async standardizeAddress(command: IStandardizeAddressCommand): Promise<ICandidateAddress> {
        return httpClient.post<ICandidateAddress>(
            `/standardizeAddresses`, command, { headers: { useGlobalLoader: true, useToastNotification: false } });
    }

    public async standardizeAddressByLoanIds(loanIds: number[]): Promise<any> {
        return httpClient.post(
            `/standardizeAddresses/loans`, { loanIds }, { headers: { useGlobalLoader: true, useToastNotification: false } });
    }

    public async standardizeAddressByDealId(dealId: number): Promise<any> {
        return httpClient.post(
            `/standardizeAddresses/deal`, { dealId }, { headers: { useGlobalLoader: true, useToastNotification: false } });
    }

    public async getStates(): Promise<any> {
        return httpClient.get(
            `/standardizeAddresses/states`, { headers: { useGlobalLoader: true, useToastNotification: false } });
    }
}

export default new StandardizeAddressService();